import React, { useState } from "react";
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useApi } from "../../../../contexts/Apicontext";
import EditorComponents from "./EditorComponent"; // EditorComponents import 추가


const BoardForm = ({ onClose, item, isAdmin }) => {

  const boardID = 2;
  const [title, setTitle] = useState(item ? item.title : "");
  const [content, setContent] = useState(item ? item.content : ""); // content state 추가
  const apiBaseUrl = useApi();

  const handleSubmit = async () => {
    const method = item ? "PUT" : "POST";
    const url = item ?
      `${apiBaseUrl}/posts/${item.postID}`
      : `${apiBaseUrl}/boards/${boardID}/posts`;
    
    const token = localStorage.getItem("token");

    try {
      await fetch(url, {
        method,
        headers: { "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
         },
        body: JSON.stringify({ title, content }), // content 포함
      });
      onClose();
    } catch (error) {
      console.error("Failed to submit board item:", error);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{item ? "게시물 수정" : "게시물 생성"}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="제목"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        {/* EditorComponents 컴포넌트를 사용하여 리치 텍스트 에디터로 변경 */}
        <EditorComponents
          styleName="custom-editor"
          content={content}
          setContent={setContent}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">취소</Button>
        <Button onClick={handleSubmit} color="primary">
          {item ? "수정" : "생성"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoardForm;
