import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
} from '@mui/material';
import { useApi } from '../../../contexts/Apicontext';
import { useParams } from 'react-router-dom';
import AttemptDetailsDialog from './AttemptDetailsDialog'; // 다이얼로그 컴포넌트 임포트

const QuizBundleDetails = ({ bundleID }) => {
    const apiBaseUrl = useApi();
    const { userID } = useParams();
    const [attemptsData, setAttemptsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openMainDialog, setOpenMainDialog] = useState(false);
    const [openSecondaryDialog, setOpenSecondaryDialog] = useState(false);

    const [currentAttemptID, setCurrentAttemptID] = useState(null);

    useEffect(() => {
        fetchBundleAttempts();
    }, [bundleID]);

    const fetchBundleAttempts = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${apiBaseUrl}/admin/users/${userID}/quizbundles/${bundleID}/attempts`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            setAttemptsData(data);
        } catch (error) {
            console.error('Failed to fetch bundle attempts:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = (attemptID, type) => {
        setCurrentAttemptID(attemptID);
        if (type === 'main') setOpenMainDialog(true);
        else if (type === 'secondary') setOpenSecondaryDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenMainDialog(false);
        setOpenSecondaryDialog(false);
        setCurrentAttemptID(null);
    };

    return (
        <Box sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
            <Typography variant="subtitle1" gutterBottom>
                Quiz Bundle Attempts for Bundle ID: {bundleID}
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : attemptsData ? (
                <Grid container spacing={2}>
                    {attemptsData.quizBundleAttempts.map((bundleAttempt) => {
                        const mainAttempt = attemptsData.quizAttempts.find(
                            (attempt) => attempt.attemptID === bundleAttempt.mainAttemptID
                        );
                        const secondaryAttempt = attemptsData.quizAttempts.find(
                            (attempt) => attempt.attemptID === bundleAttempt.secondaryAttemptID
                        );

                        return (
                            <React.Fragment key={bundleAttempt.bundleAttemptID}>
                                {/* Total Score */}
                                <Grid item xs={4}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: 'bold',
                                                marginBottom: 1,
                                            }}
                                        >
                                            Total Score
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#1976d2',
                                            }}
                                        >
                                            {bundleAttempt.totalScore}
                                        </Typography>
                                    </Paper>
                                </Grid>

                                {/* Main and Secondary Attempts */}
                                <Grid item xs={8}>
                                    <Paper sx={{ p: 2, height: '100%' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            {/* Main Quiz Attempt */}
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Main Quiz Attempt
                                                </Typography>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableBody>
                                                            {mainAttempt ? (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell>Quiz ID</TableCell>
                                                                        <TableCell>{mainAttempt.quizID}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>Start Time</TableCell>
                                                                        <TableCell>
                                                                            {new Date(mainAttempt.startTime).toLocaleString()}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>End Time</TableCell>
                                                                        <TableCell>
                                                                            {new Date(mainAttempt.endTime).toLocaleString()}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>Score</TableCell>
                                                                        <TableCell>{mainAttempt.score}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>Test Time (seconds)</TableCell>
                                                                        <TableCell>{mainAttempt.testTime}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell colSpan={2} align="center">
                                                                        No Main Attempt Data
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {mainAttempt && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ marginTop: 2 }}
                                                        onClick={() =>
                                                            handleOpenDialog(mainAttempt.attemptID, 'main')
                                                        }
                                                    >
                                                        main 시험 결과보기
                                                    </Button>
                                                )}
                                            </Box>

                                            {/* Secondary Quiz Attempt */}
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Secondary Quiz Attempt
                                                </Typography>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableBody>
                                                            {secondaryAttempt ? (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell>Quiz ID</TableCell>
                                                                        <TableCell>{secondaryAttempt.quizID}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>Start Time</TableCell>
                                                                        <TableCell>
                                                                            {new Date(secondaryAttempt.startTime).toLocaleString()}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>End Time</TableCell>
                                                                        <TableCell>
                                                                            {new Date(secondaryAttempt.endTime).toLocaleString()}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>Score</TableCell>
                                                                        <TableCell>{secondaryAttempt.score}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>Test Time (seconds)</TableCell>
                                                                        <TableCell>{secondaryAttempt.testTime}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell colSpan={2} align="center">
                                                                        No Secondary Attempt Data
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {secondaryAttempt && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ marginTop: 2 }}
                                                        onClick={() =>
                                                            handleOpenDialog(secondaryAttempt.attemptID, 'secondary')
                                                        }
                                                    >
                                                        Secondary 시험 결과보기
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            ) : (
                <Typography variant="body2">No attempts data available.</Typography>
            )}

            {/* Main Attempt Dialog */}
            {currentAttemptID && (
                <AttemptDetailsDialog
                    attemptID={currentAttemptID}
                    open={openMainDialog || openSecondaryDialog}
                    onClose={handleCloseDialog}
                />
            )}
        </Box>
    );
};

export default QuizBundleDetails;