import React, { useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";

const StorageItems = ({ item, type, onClick, onDelete, isAdmin }) => {


  useEffect(() => {
    console.log(item);
    console.log(item.name);
    console.log(item.file_name);
  }, [item]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
        border: "1px solid #ddd",
        borderRadius: "8px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f7f7f7",
        },
      }}
    >
      {/* Left Side: Folder/File Icon and Name */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          flex: 1, // Take up available space
        }}
        onClick={onClick}
      >
        {type === "folder" ? (
          <FolderIcon fontSize="large" sx={{ color: "#6c6c6c" }} />
        ) : (
          <InsertDriveFileIcon fontSize="large" sx={{ color: "#6c6c6c" }} />
        )}
        <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {item.name || item.file_name}
        </Typography>
      </Box>
      <Box >
        {isAdmin ? (
          < IconButton
            onClick={(e) => {
              e.stopPropagation(); // Prevent click event from triggering parent `onClick`
              onDelete(item.id); // Call delete function with item ID
            }}
            aria-label="delete"
            sx={{
              color: "#d32f2f",
              "&:hover": {
                color: "#b71c1c",
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        ): null}
      </Box>
    </Box >
  );
};

export default StorageItems;