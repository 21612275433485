import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress'; // CircularProgress 컴포넌트 추가
import ClassList from './BodyComponents/ClassList';
import Loading from '../Loading/Loading';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import theme from '../../theme/theme';
import { ThemeProvider } from '@mui/material';

export default function StudentMainPageBody({ userID, name, email, classes, loading }) {
    const [filter, setFilter] = useState('active'); // 상태 관리: all, active, finished

    const filteredClasses = () => {
        if (filter === 'active') {
            return classes.filter(cls => cls.isFinished === 0);
        } else if (filter === 'finished') {
            return classes.filter(cls => cls.isFinished === 1);
        }
        return classes; // 기본적으로 모든 수업 반환
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    paddingLeft: '20%',
                    paddingRight: '20%',
                    flexGrow: 1,
                    mb: 10
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 'full',
                        mt: 10
                    }}
                >
                    <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
                        <Typography variant="h4" component="h4" sx={{ fontFamily: 'monospace', wordBreak: 'keep-all', fontWeight: 'bold' }}>
                            환영합니다! {name}님
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 6 }}>
                    <Box sx={{ display: 'flex', mb: 4 }}>
                        <Box sx={{ mt: 0, mx: 3 }}>
                            <Box sx={{ display: 'flex', mb: 4 }}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid #ddd',
                                        borderRadius: '8px',
                                        padding: '16px'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                        수업 중
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '3rem',
                                            color: '#333',
                                            mb: 2
                                        }}
                                    >
                                        {classes.filter(cls => cls.isFinished === 0).length || 0}
                                    </Typography>
                                    <Divider sx={{ width: '100%', mb: 2 }} />
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        수업 상태를 선택해서 볼 수 있어요
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setFilter('active')}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                backgroundColor:
                                                    filter === 'active'
                                                        ? theme.palette.custom.default
                                                        : 'transparent',
                                                border: filter === 'active' ? 'none' : '1px solid #ccc',
                                                color: filter === 'active' ? '#fff' : '#333',
                                                '&:hover': {
                                                    backgroundColor:
                                                        filter === 'active'
                                                            ? theme.palette.custom.dark
                                                            : 'rgba(0, 0, 0, 0.04)'
                                                }
                                            }}
                                        >
                                            수강 중 ({classes.filter(cls => cls.isFinished === 0).length || 0})
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => setFilter('finished')}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                backgroundColor:
                                                    filter === 'finished'
                                                        ? theme.palette.custom.default
                                                        : 'transparent',
                                                border: filter === 'finished' ? 'none' : '1px solid #ccc',
                                                color: filter === 'finished' ? '#fff' : '#333',
                                                '&:hover': {
                                                    backgroundColor:
                                                        filter === 'finished'
                                                            ? theme.palette.custom.dark
                                                            : 'rgba(0, 0, 0, 0.04)'
                                                }
                                            }}
                                        >
                                            종강 ({classes.filter(cls => cls.isFinished === 1).length || 0})
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#FFF7D1',
                                            borderRadius: '8px',
                                            padding: '8px',
                                            marginTop: '16px',
                                            width: '100%',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: '#333',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px'
                                            }}
                                        >
                                            신규 수강생이신가요?{' '}
                                            <Button
                                                variant="text"
                                                sx={{
                                                    color: '#007BFF',
                                                    textTransform: 'none',
                                                    padding: 0
                                                }}
                                            >
                                                AIO 이용 가이드
                                            </Button>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 0, display: 'flex', width: '70%', flexDirection: 'column' }}>
                            {loading ? ( // 로딩 중일 때 로딩 컴포넌트 표시
                                <Loading />
                            ) : filteredClasses().length > 0 ? (
                                <ClassList classes={filteredClasses()} />
                            ) : (
                                <Typography variant="body1">해당하는 강의가 없습니다.</Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}