import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Button, Divider, CircularProgress, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MenuIcon from "@mui/icons-material/Menu";
import { useApi } from "../../../../contexts/Apicontext";

const BoardDetail = () => {
    const location = useLocation();
    const { boardID, postID } = useParams();
    const { boardItems: initialBoardItems = [], limit = 5 } = location.state || {};
    const navigate = useNavigate();
    const [boardItems, setBoardItems] = useState(initialBoardItems);
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isFetchingBoardItems, setIsFetchingBoardItems] = useState(false);
    const apiBaseUrl = useApi();
    const currentPage = parseInt(new URLSearchParams(location.search).get("currentPage")) || 1;

    useEffect(() => {
        fetchPostData();
    }, [postID]);

    const fetchPostData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${apiBaseUrl}/boards/${boardID}/posts/${postID}`);
            const data = await response.json();
            setPostData(data);
        } catch (error) {
            console.error("Error fetching post data:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBoardItems = async (page) => {
        try {
            setIsFetchingBoardItems(true);
            const response = await fetch(
                `${apiBaseUrl}/boards/${boardID}/posts?page=${page}&limit=${limit}`
            );
            const data = await response.json();
            setBoardItems(data.items);
            return data.items;
        } catch (error) {
            console.error("Error fetching board items:", error);
            return [];
        } finally {
            setIsFetchingBoardItems(false);
        }
    };

    const currentIndex = boardItems.findIndex((item) => item.postID === parseInt(postID, 10));

    const handlePrevious = async () => {
        if (currentIndex === 0 && currentPage > 1) {
            const newBoardItems = await fetchBoardItems(currentPage - 1);
            if (newBoardItems.length > 0) {
                const lastPost = newBoardItems[newBoardItems.length - 1];
                navigate(
                    `/board/${boardID}/post/${lastPost.postID}?currentPage=${currentPage - 1}`,
                    { state: { boardItems: newBoardItems, limit } }
                );
            }
        } else if (currentIndex > 0) {
            const previousPost = boardItems[currentIndex - 1];
            navigate(`/board/${boardID}/post/${previousPost.postID}?currentPage=${currentPage}`, {
                state: { boardItems, limit },
            });
        }
    };

    const handleNext = async () => {
        if (currentIndex === boardItems.length - 1) {
            const newBoardItems = await fetchBoardItems(currentPage + 1);
            if (newBoardItems.length > 0) {
                const firstPost = newBoardItems[0];
                navigate(
                    `/board/${boardID}/post/${firstPost.postID}?currentPage=${currentPage + 1}`,
                    { state: { boardItems: newBoardItems, limit } }
                );
            }
        } else if (currentIndex < boardItems.length - 1) {
            const nextPost = boardItems[currentIndex + 1];
            navigate(`/board/${boardID}/post/${nextPost.postID}?currentPage=${currentPage}`, {
                state: { boardItems, limit },
            });
        }
    };

    const isPreviousDisabled = () => {
        return (
            isFetchingBoardItems || 
            (currentIndex === 0 && currentPage === 1)
        );
    };

    const isNextDisabled = () => {
        return (
            isFetchingBoardItems || 
            (currentIndex === boardItems.length - 1 && boardItems.length < limit)
        );
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "center", py: 5, minHeight: "900px" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    maxWidth: "800px",
                    bgcolor: "white",
                    minHeight: "600px",
                    borderRadius: 2,
                    p: 4,
                    position: "relative",
                }}
            >
                <IconButton
                    onClick={() => navigate("/board/announcement")}
                    sx={{
                        position: "absolute",
                        top: -20,
                        left: -20,
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        width: "48px",
                        height: "48px",
                    }}
                >
                    <MenuIcon sx={{ fontSize: "24px", color: "#333" }} />
                </IconButton>

                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}>
                    {postData.title}
                </Typography>

                <Typography variant="body2" color="textSecondary" sx={{ textAlign: "center", mb: 3 }}>
                    작성일: {new Date(postData.createdAt).toLocaleString()} | 수정일:{" "}
                    {new Date(postData.updatedAt).toLocaleString()}
                </Typography>

                <Divider sx={{ mb: 3 }} />

                <Box
                    sx={{ lineHeight: "1.8", fontSize: "1rem", mb: 4, flexGrow: 1 }}
                    dangerouslySetInnerHTML={{ __html: postData.content }}
                />

                <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: "auto" }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handlePrevious}
                        disabled={isPreviousDisabled()}
                    >
                        이전
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={isNextDisabled()}
                    >
                        다음
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default BoardDetail;