import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, IconButton, Divider, TextField, InputAdornment } from '@mui/material';
import { useApi } from '../../../contexts/Apicontext';
import CourseItem from './CourseItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ClearIcon from '@mui/icons-material/Clear';

export default function CoursesPage() {
    const [courses, setCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('price');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [showFavorites, setShowFavorites] = useState(false);
    const apiBaseUrl = useApi();
    const token = localStorage.getItem('token'); // Make sure to define token here

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/${token !== null ? 'all-classes' : 'public-classes'}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        ...(token && { 'Authorization': `Bearer ${token}` }), // Include authorization header if the user is logged in
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setCourses(data);
                    setFilteredCourses(data); // Initialize with all courses
                } else {
                    console.error('Failed to fetch courses:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, [apiBaseUrl, token]);

    const handleSortOrderChange = (criteria) => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        setSortCriteria(criteria);
        sortCourses(criteria, newOrder);
    };

    const sortCourses = (criteria, order) => {
        const sortedCourses = [...filteredCourses].sort((a, b) => {
            if (criteria === 'price') {
                return order === 'asc' ? a.price - b.price : b.price - a.price;
            } else if (criteria === 'servicePeriod') {
                return order === 'asc' ? a.servicePeriod - b.servicePeriod : b.servicePeriod - a.servicePeriod;
            }
            return 0;
        });
        setFilteredCourses(sortedCourses);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            const filtered = courses.filter(course => course.className.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredCourses(filtered);
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        setFilteredCourses(courses); // Reset to all courses when cleared
    };

    const toggleShowFavorites = () => {
        setShowFavorites(prevShowFavorites => {
            const newShowFavorites = !prevShowFavorites;

            if (newShowFavorites) {
                setFilteredCourses(courses.filter(course => course.isFavorite));
            } else {
                setFilteredCourses(courses);
            }

            return newShowFavorites;
        });
    };

    return (
        <Box sx={{}}>
            <Grid container spacing={4}>
                {/* Left Sidebar */}
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center', mt: 10, width: '70%' }}>
                        <Divider sx={{ width: '100%', mb: 2, borderColor: 'black', borderWidth: '2.5px' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>수강 신청</Typography>
                            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>{filteredCourses.length}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, width: '100%' }}>
                            <TextField
                                variant="standard"
                                placeholder="수업 검색"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyPress={handleSearchKeyPress}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchTerm && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={clearSearch}>
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true, // Removes the default underline
                                }}
                                sx={{
                                    paddingLeft: '10px',
                                    paddingBottom: '8px',
                                    borderBottom: '1.5px solid #000', // Custom underline style
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, color: '#aaa', fontSize: '12px' }}>
                                <HelpOutlineIcon sx={{ fontSize: '16px', mr: 1 }} />
                                예: AI, MOCK TEST, MATH 등
                            </Box>
                        </Box>
                        <Divider sx={{ width: '100%', mt: 2, borderColor: 'black', borderWidth: '2.5px' }} />

                        {/* Favorites Filter Button */}
                        {token && (  // Show the favorites button only if the user is logged in
                            <Button
                                variant="outlined"
                                sx={{ mt: 2, width: '100%', borderColor: '#aaa', color: '#aaa', textTransform: 'none' }}
                                onClick={toggleShowFavorites}
                            >
                                찜 {showFavorites ? '해제' : '보기'} ({courses.filter(course => course.isFavorite).length})
                            </Button>
                        )}
                    </Box>
                </Grid>

                {/* Right Content: Course List */}
                <Grid item xs={12} md={9}>
                    <Typography variant="body1" sx={{ mt: 4 }}>
                        Here you can find a selection of courses available for purchase.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Choose a course that fits your needs and start learning today!
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                onClick={() => handleSortOrderChange('price')}
                                endIcon={sortCriteria === 'price' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : <ArrowDropDownIcon />}
                                sx={{ textTransform: 'none', borderRadius: 0, borderBottom: '2px solid #000', ml: 2, color: 'black' }}
                            >
                                가격
                            </Button>
                            <Button
                                onClick={() => handleSortOrderChange('servicePeriod')}
                                endIcon={sortCriteria === 'servicePeriod' ? (sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : <ArrowDropDownIcon />}
                                sx={{ textTransform: 'none', borderRadius: 0, borderBottom: '2px solid #000', ml: 2, color: 'black' }}
                            >
                                서비스 기간
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                        {filteredCourses.map((course) => (
                            <CourseItem key={course.classID} course={course} />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
