import React, { useState } from 'react';
import { Box, Toolbar, Container } from '@mui/material';
import AdminHeader from './AdminHeader';
import AdminMenuDrawer from './AdminMenuDrawer';
import UsersTable from './UsersTable';
import UserDetailPage from './UserDetailPage';
import { Routes, Route } from 'react-router-dom';
import QuizList from './user_attempt/QuizzesAndBundlesPage';

const drawerWidth = 240;

const UserAdminPage = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader handleDrawerToggle={handleDrawerToggle} />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    width: { sm: '100%' } // Calculate width excluding drawer
                }}
            >
                <Toolbar />
                <Box sx={{ width: '100%' }}>
                    <Routes>
                        <Route path="/" element={<UsersTable />} />
                        <Route path="/:userID/details" element={<UserDetailPage />} />
                        <Route path='/:userID/details/classID/:classID/lectureID/:lectureID' element={<QuizList />} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default UserAdminPage;
