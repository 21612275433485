import React, { useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const Stopwatch = ({ timer, setTimer, isTimerRunning, toggleTimer, resetTimer, onTimerEnd }) => {

    useEffect(() => {
        let interval;

        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer > 0) {
                        return prevTimer - 1;
                    } else {
                        clearInterval(interval);
                        onTimerEnd();
                        return 0;
                    }
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, setTimer, onTimerEnd]);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#0f3e8e',
            },
            custom: {
                light: '#4f7aa2',
                dark: '#0a2b4d',
                default: '#0f3e8e',
                darker: '#0a1e3c',
                lightest: '#f0f0f0',
                black: '#000000'
            }
        },
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.custom.darker,
                        fontSize: { xs: '1.0rem', sm: '1.2rem', lg: '2rem' }, // 반응형 크기 설정
                        textAlign: 'center', // 중앙 정렬
                    }}
                >
                    Timer: {Math.floor(timer / 60)}:
                    {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                </Typography>
            </Box>
            <Box>
                <Button
                    size="small"
                    onClick={toggleTimer}
                    sx={{
                        mr: 1,
                        bgcolor: theme.palette.custom.black,
                        color: 'white',
                        borderRadius: '20px',
                        borderWidth: 2,
                        padding: { xs: '2px 6px', lg: '4px 10px' }, // lg 이상은 기본 크기, lg 이하는 작아짐
                        fontSize: { xs: '0.7rem', lg: '0.9rem' }, // 글씨 크기 조정
                        '&:hover': { bgcolor: 'grey' },
                    }}
                >
                    {isTimerRunning ? 'Pause' : 'Start'}
                </Button>
                <Button
                    size="small"
                    onClick={resetTimer}
                    sx={{
                        bgcolor: theme.palette.custom.black,
                        color: 'white',
                        borderRadius: '20px',
                        padding: { xs: '2px 6px', lg: '4px 10px' }, // lg 이상은 기본 크기, lg 이하는 작아짐
                        fontSize: { xs: '0.7rem', lg: '0.9rem' }, // 글씨 크기 조정
                        '&:hover': { bgcolor: 'grey' },
                    }}
                >
                    Reset
                </Button>
            </Box>
        </Box>
    );
};

export default Stopwatch;
