import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Collapse,
    IconButton,
    Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../contexts/Apicontext';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import QuizDetails from './QuizDetails';
import QuizBundleDetails from './QuizBundleDetails';
import { useNavigate } from 'react-router-dom';

const QuizzesAndBundlesPage = () => {
    const { classID, lectureID } = useParams();
    const apiBaseUrl = useApi();
    const [quizzes, setQuizzes] = useState([]);
    const [quizBundles, setQuizBundles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedQuizID, setExpandedQuizID] = useState(null);
    const [expandedBundleID, setExpandedBundleID] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchQuizzesAndBundles();
    }, [classID, lectureID]);

    const fetchQuizzesAndBundles = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${apiBaseUrl}/admin/users/classes/${classID}/lectures/${lectureID}/quizzes-and-bundles`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            setQuizzes(data.quizzes || []);
            setQuizBundles(data.quizBundles || []);
        } catch (error) {
            console.error('Failed to fetch quizzes and quiz bundles:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleExpandQuiz = (quizID) => {
        setExpandedQuizID((prev) => (prev === quizID ? null : quizID));
    };

    const toggleExpandBundle = (bundleID) => {
        setExpandedBundleID((prev) => (prev === bundleID ? null : bundleID));
    };

    const cellStyle = {
        padding: '10px 10px', // 기본 패딩
        lineHeight: '1.2rem', // 줄 간격
        minHeight: '40px', // 최소 높이
    };

    return (
        <Box sx={{ p: 3 }}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)} sx={{ mb: 3 }}>
                Back
            </Button>
            <Typography variant="h5" gutterBottom>
                Quizzes and Quiz Bundles for Class ID: {classID}, Lecture ID: {lectureID}
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <Box>
                    {/* Quizzes Table */}
                    <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                        Quizzes
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyle} />
                                    <TableCell sx={cellStyle}>Quiz ID</TableCell>
                                    <TableCell sx={cellStyle}>Quiz Name</TableCell>
                                    <TableCell sx={cellStyle}>Description</TableCell>
                                    <TableCell sx={cellStyle}>Category</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quizzes.length > 0 ? (
                                    quizzes.map((quiz) => (
                                        <React.Fragment key={quiz.quizID}>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>
                                                    <IconButton onClick={() => toggleExpandQuiz(quiz.quizID)}>
                                                        {expandedQuizID === quiz.quizID ? (
                                                            <KeyboardArrowUp />
                                                        ) : (
                                                            <KeyboardArrowDown />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell sx={cellStyle}>{quiz.quizID}</TableCell>
                                                <TableCell sx={cellStyle}>{quiz.quizName}</TableCell>
                                                <TableCell sx={cellStyle}>{quiz.description}</TableCell>
                                                <TableCell sx={cellStyle}>{quiz.category}</TableCell>
                                            </TableRow>
                                            {/* Expandable Details Row */}
                                            <TableRow>
                                                <TableCell colSpan={5} sx={{ p: 0, borderBottom: 'none' }}>
                                                    <Collapse
                                                        in={expandedQuizID === quiz.quizID}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <QuizDetails quizID={quiz.quizID} />
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell sx={cellStyle} colSpan={5} align="center">
                                            No quizzes available.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Quiz Bundles Table */}
                    <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                        Quiz Bundles
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyle} />
                                    <TableCell sx={cellStyle}>Bundle ID</TableCell>
                                    <TableCell sx={cellStyle}>Bundle Name</TableCell>
                                    <TableCell sx={cellStyle}>Main Quiz ID</TableCell>
                                    <TableCell sx={cellStyle}>Easy Quiz ID</TableCell>
                                    <TableCell sx={cellStyle}>Hard Quiz ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quizBundles.length > 0 ? (
                                    quizBundles.map((bundle) => (
                                        <React.Fragment key={bundle.bundleID}>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>
                                                    <IconButton onClick={() => toggleExpandBundle(bundle.bundleID)}>
                                                        {expandedBundleID === bundle.bundleID ? (
                                                            <KeyboardArrowUp />
                                                        ) : (
                                                            <KeyboardArrowDown />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell sx={cellStyle}>{bundle.bundleID}</TableCell>
                                                <TableCell sx={cellStyle}>{bundle.bundleName}</TableCell>
                                                <TableCell sx={cellStyle}>{bundle.mainQuizID}</TableCell>
                                                <TableCell sx={cellStyle}>{bundle.easyQuizID}</TableCell>
                                                <TableCell sx={cellStyle}>{bundle.hardQuizID}</TableCell>
                                            </TableRow>
                                            {/* Expandable Details Row */}
                                            <TableRow>
                                                <TableCell colSpan={6} sx={{ p: 0, borderBottom: 'none' }}>
                                                    <Collapse
                                                        in={expandedBundleID === bundle.bundleID}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <QuizBundleDetails bundleID={bundle.bundleID} />
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell sx={cellStyle} colSpan={6} align="center">
                                            No quiz bundles available.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};

export default QuizzesAndBundlesPage;