import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Typography,
    Button,
    Box,
    Collapse,
    IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useApi } from '../../../contexts/Apicontext';
import './quillEditorStyles.css';

const AttemptDetailsDialog = ({ attemptID, open, onClose }) => {
    const apiBaseUrl = useApi();
    const [dialogData, setDialogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        if (open && attemptID) {
            fetchAttemptResponses();
        }
    }, [open, attemptID]);

    const fetchAttemptResponses = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${apiBaseUrl}/admin/users/attempts/${attemptID}/responses`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            console.log('Attempt responses:', data);
            setDialogData(data);
        } catch (error) {
            console.error('Failed to fetch attempt responses:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleExpand = (rowIndex) => {
        setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
    };

    const handleClose = () => {
        setDialogData([]);
        setLoading(false);
        onClose();
    };

    const renderContent = (content) => {
        if (!Array.isArray(content)) return null;
        return content.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: item.value }} />
                    </div>
                );
            } else if (item.type === 'image') {
                return <img key={index} src={item.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />;
            } else {
                return null;
            }
        });
    };

    const renderOptions = (options) => {
        if (!Array.isArray(options)) return null;
        return options.map((option, index) => {
            if (option.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: `<strong>${option.name}</strong>: ${option.value}` }} />
                    </div>
                );
            } else if (option.type === 'image') {
                return (
                    <div key={index}>
                        <Typography><strong>{option.name}:</strong></Typography>
                        <img src={option.value} alt={`option-${index}`} style={{ maxWidth: '100%' }} />
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>Attempt Details for Attempt ID: {attemptID}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>문제 번호</TableCell>
                                <TableCell>질문</TableCell>
                                <TableCell>학생 답변</TableCell>
                                <TableCell>결과</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dialogData.map((item, index) => (
                                <React.Fragment key={item.questionID}>
                                    <TableRow
                                        style={{
                                            backgroundColor: item.response.isCorrect
                                                ? 'rgba(173, 216, 230, 0.3)' // 연한 파란색
                                                : 'rgba(255, 182, 193, 0.3)', // 연한 빨간색
                                        }}
                                    >
                                        <TableCell>{item.order}번</TableCell>
                                        <TableCell>{item.questiontext}</TableCell>
                                        <TableCell>{item.response.selectedOption}</TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: 'black',
                                                }}
                                            >
                                                {item.response.isCorrect ? '정답' : '오답'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => toggleExpand(index)}>
                                                {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ padding: 0 }}>
                                            <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                                <Box sx={{ padding: 2, border: '1px solid #ddd' }}>
                                                    <Typography variant="h6">세부 정보</Typography>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        <strong>문항 내용:</strong>
                                                    </Typography>
                                                    {renderContent(item.content)}
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        <strong>선택지:</strong>
                                                    </Typography>
                                                    {renderOptions(item.options)}
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        <strong>정답:</strong> {item.answer}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        <strong>질문 유형:</strong> {item.questionType}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        <strong>학생 답변:</strong> {item.response.selectedOption}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                                        <strong>정답 여부:</strong> {item.response.isCorrect ? '정답' : '오답'}
                                                    </Typography>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <Button onClick={handleClose} variant="contained" sx={{ m: 2 }}>
                닫기
            </Button>
        </Dialog>
    );
};

export default AttemptDetailsDialog;