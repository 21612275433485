import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Container, Grid, Typography, Paper, List, Button, Divider, IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Fab
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Stopwatch from './Stopwatch';
import DictionarySearch from './DictionarySearch';
import QuizOption from './QuizOption';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ConfirmLeaveDialog from './ConfirmLeaveDialog';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SquareIcon from '@mui/icons-material/Square';
import MathDirection from './MathDirection';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EnglishDirection from './EnglishDirection';
import MathRefSheet from './MathRefSheet';
import SuperscriptSharpIcon from '@mui/icons-material/SuperscriptSharp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighlightIcon from '@mui/icons-material/Highlight';
import { MenuItem, Select } from '@mui/material';
import DesmosCalculator from './DesmosCalculator';
import theme from '../../theme/theme';



function MockTestPage() {
  const { lectureID, attemptID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { mockTest, classID, category, quizID, onQuizEnd, isBundleTest } = location.state || {};  // onQuizEnd와 isBundleTest 추가
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem(`currentOrder_${classID}_${lectureID}_${quizID}`);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem(`timer_${classID}_${lectureID}_${quizID}`);
    const time = category === "Math" ? 2100 : 1920;
    return savedTimer ? parseInt(savedTimer, 10) : time;
  });
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState(() => {
    const savedQuestions = localStorage.getItem(`questions_${classID}_${lectureID}_${quizID}`);
    return savedQuestions ? JSON.parse(savedQuestions) : [];
  });
  const [selectedOption, setSelectedOption] = useState(() => {
    const savedQuestions = localStorage.getItem(`questions_${classID}_${lectureID}_${quizID}`);
    const currentQ = savedQuestions ? JSON.parse(savedQuestions)[currentIndex] : null;
    return currentQ ? currentQ.selectedOption : '';
  });
  const [isLoading, setIsLoading] = useState(true);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [listDialogOpen, setListDialogOpen] = useState(false);
  const [isHighlightNotesSelected, setIsHighlightNotesSelected] = useState(false);
  const [maximizeLeft, setMaximizeLeft] = useState(false);
  const [maximizeRight, setMaximizeRight] = useState(false);
  const [showCancelIcon, setShowCancelIcon] = useState(false);
  const [directionsDialogOpen, setDirectionsDialogOpen] = useState(false);
  const [refsheetsDialogOpen, setRefsheetsDialogOpen] = useState(false);
  const [highlightDialogOpen, setHighlightDialogOpen] = useState(false);
  const directionsButtonRef = useRef(null);
  const refsheetsButtonRef = useRef(null);
  const [highlightDialogPosition, setHighlightDialogPosition] = useState({ top: 0, left: 0 });
  const [underlineStyle, setUnderlineStyle] = useState('none');
  const [highlights, setHighlights] = useState(() => {
    const savedHighlights = localStorage.getItem(`highlights_${classID}_${lectureID}_${quizID}`);
    return savedHighlights ? JSON.parse(savedHighlights) : {}; // Ensure highlights is always an object
  });


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDirectionsDialogOpen = () => setDirectionsDialogOpen(true);
  const handleDirectionsDialogClose = () => setDirectionsDialogOpen(false);
  const handleRefSheetsDialogOpen = () => setRefsheetsDialogOpen(true);
  const handleRefSheetsDialogClose = () => setRefsheetsDialogOpen(false);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isCheckPage, setIsCheckPage] = useState(false);

  useEffect(() => {
    const savedQuestions = localStorage.getItem(`questions_${classID}_${lectureID}_${quizID}`);
    const savedMockTest = localStorage.getItem(`mockTest_${classID}_${lectureID}_${quizID}`);
    const savedTimer = localStorage.getItem(`timer_${classID}_${lectureID}_${quizID}`);
    const savedIndex = localStorage.getItem(`currentOrder_${classID}_${lectureID}_${quizID}`);
    const savedHighlights = localStorage.getItem(`highlights_${classID}_${lectureID}_${quizID}`);


    if (savedQuestions && savedMockTest && savedTimer && savedIndex) {
      setQuestions(JSON.parse(savedQuestions));
      setCurrentIndex(parseInt(savedIndex, 10));
      setTimer(parseInt(savedTimer, 10));
      setCurrentQuestion(JSON.parse(savedQuestions)[parseInt(savedIndex, 10)]);
      setIsLoading(false);
      setHighlights(JSON.parse(savedHighlights));
    } else if (mockTest && mockTest.questions) {
      const initializedQuestions = mockTest.questions.map(q => ({ ...q, isResponsed: false, isMarked: false, selectedOption: '' }));
      setQuestions(initializedQuestions);
      localStorage.setItem(`mockTest_${classID}_${lectureID}_${quizID}`, JSON.stringify(mockTest));
      setCurrentQuestion(initializedQuestions[currentIndex]);
      setIsLoading(false);
    }
  }, [mockTest]);

  useEffect(() => {
    if (questions.length > 0) {
      localStorage.setItem(`questions_${classID}_${lectureID}_${quizID}`, JSON.stringify(questions));
      localStorage.setItem(`currentOrder_${classID}_${lectureID}_${quizID}`, currentIndex.toString());
      localStorage.setItem(`timer_${classID}_${lectureID}_${quizID}`, timer.toString());
      setCurrentQuestion(questions[currentIndex]);
    }
  }, [questions, currentIndex, timer]);

  useEffect(() => {
    if (highlights && Object.keys(highlights).length > 0) { // Add a check to ensure highlights is defined
      localStorage.setItem(`highlights_${classID}_${lectureID}_${quizID}`, JSON.stringify(highlights));
    }
  }, [highlights]);


  const handleNextQuestion = () => {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
      const currentQ = questions[currentIndex + 1];
      setSelectedOption(currentQ?.selectedOption || '');
    }
  };

  const handlePrevQuestion = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      const currentQ = questions[currentIndex - 1];
      setSelectedOption(currentQ?.selectedOption || '');
    }
  };

  const toggleTimer = () => {
    setIsTimerRunning(!isTimerRunning);
  };

  const resetTimer = () => {
    setIsLoading(true);
    setTimeout(() => {
      const savedMockTest = localStorage.getItem(`mockTest_${classID}_${lectureID}_${quizID}`);
      if (savedMockTest) {
        const mockTestData = JSON.parse(savedMockTest);
        const initializedQuestions = mockTestData.questions.map(q => ({ ...q, isResponsed: false, isMarked: false, selectedOption: '' }));
        setQuestions(initializedQuestions);
        setCurrentIndex(0);
        setSelectedOption('');
        setIsCheckPage(false);
        setTimer(category === "Math" ? 2100 : 1920);
        localStorage.removeItem(`timer_${classID}_${lectureID}_${quizID}`);
        localStorage.removeItem(`questions_${classID}_${lectureID}_${quizID}`);
        localStorage.removeItem(`currentOrder_${classID}_${lectureID}_${quizID}`);
        localStorage.removeItem(`highlights_${classID}_${lectureID}_${quizID}`);
      }
      setIsLoading(false);
    }, 500);
  };

  const handleToggleBookmark = () => {
    const updatedQuestions = questions.map((question, index) => {
      if (index === currentIndex) {
        return { ...question, isMarked: !question.isMarked };
      }
      return question;
    });
    setQuestions(updatedQuestions);
  };

  const handleAttemptLeave = () => {
    setLeaveDialogOpen(true);
  };

  const handleLeaveConfirm = () => {
    setLeaveDialogOpen(false);
    navigate(-1);
  };

  const handleLeaveCancel = () => {
    setLeaveDialogOpen(false);
  };

  const handleListOpen = () => {
    setListDialogOpen(true);
  };

  const handleListClose = () => {
    setListDialogOpen(false);
  };

  const handleJumpToQuestion = (index) => {
    setCurrentIndex(index);
    const currentQ = questions[index];
    setSelectedOption(currentQ?.selectedOption || '');
    setListDialogOpen(false);
    setIsCheckPage(false);
  };

  const handleCheck = () => {
    setIsCheckPage(true);
  };

  const handleSubmit = () => {
    const totalDifficulty = questions.reduce((sum, question) => sum + question.difficulty, 0);
    const unitScore = 600 / totalDifficulty;
    let score = 800;

    const evaluatedQuestions = questions.map((question) => {
      const isCorrect = question.selectedOption === question.answer;

      if (!isCorrect) {
        score -= unitScore * question.difficulty;
      }
      return { ...question, isCorrect };
    });

    score = Math.round(score / 10) * 10;


    navigate(`/lectures/${lectureID}/mock-tests/${attemptID}/end`, {
      state: { questions: evaluatedQuestions, score, classID, timer, category: category, lectureID: lectureID, quizID: quizID }
    });
  };

  useEffect(() => {
    // 텍스트 복사 방지 핸들러
    const handleCopy = (event) => {
      event.preventDefault();
      alert("텍스트 복사가 허용되지 않습니다.");
    };

    // document의 copy 이벤트를 감지하여 복사 방지
    document.addEventListener('copy', handleCopy);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);


  const handleMaximizeLeft = () => {
    setMaximizeLeft(!maximizeLeft);
    if (maximizeRight) setMaximizeRight(false);
  };

  const handleMaximizeRight = () => {
    setMaximizeRight(!maximizeRight);
    if (maximizeLeft) setMaximizeLeft(false);
  };

  const handleHighlightColorChange = (color) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const highlightId = `highlight-${Date.now()}`;

    const highlightNode = document.createElement("span");

    highlightNode.style.backgroundColor = color;
    highlightNode.setAttribute("data-highlight-id", highlightId);
    highlightNode.appendChild(range.extractContents());
    range.insertNode(highlightNode);

    const newHighlight = {
      id: highlightId,
      color,
      text: highlightNode.innerText,
    };

    const updatedQuestions = questions.map((question, index) => {
      if (index === currentIndex) {
        return {
          ...question,
          highlights: [...(question.highlights || []), newHighlight]
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    setHighlightDialogOpen(false);
  };


  const handleUnderlineStyleChange = (event) => {
    const style = event.target.value;
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const underlineId = `underline-${Date.now()}`;

    const underlineNode = document.createElement("span");

    if (style !== 'none') {
      underlineNode.style.textDecoration = `underline ${style}`;
    }
    underlineNode.setAttribute("data-underline-id", underlineId);
    underlineNode.appendChild(range.extractContents());
    range.insertNode(underlineNode);

    const newUnderline = {
      id: underlineId,
      style,
      text: underlineNode.innerText,
    };

    const updatedQuestions = questions.map((question, index) => {
      if (index === currentIndex) {
        return {
          ...question,
          highlights: [...(question.highlights || []), newUnderline]
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    setUnderlineStyle('none');
    setHighlightDialogOpen(false);
  };

  useEffect(() => {
    // 드래그 이벤트 방지 핸들러 추가
    const handleDragStart = (event) => {
      event.preventDefault();
    };

    // document의 모든 요소에 대해 드래그 방지
    document.addEventListener('dragstart', handleDragStart);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener('dragstart', handleDragStart);
    };
  }, []);



  const handleRemoveHighlights = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);

    // Helper function to remove highlight/underline span while keeping its content
    const removeSpan = (span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    };

    // Process the nodes within the range
    const processNode = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE && (node.hasAttribute('data-highlight-id') || node.hasAttribute('data-underline-id'))) {
        removeSpan(node);
      } else if (node.nodeType !== Node.TEXT_NODE) {
        node.childNodes.forEach(child => {
          if (range.intersectsNode(child)) {
            processNode(child);
          }
        });
      }
    };

    processNode(range.commonAncestorContainer);

    // Remove highlight information from the state
    const updatedQuestions = questions.map((question, index) => {
      if (index === currentIndex) {
        const updatedHighlights = (question.highlights || []).filter(highlight => {
          const { text } = highlight;
          return !range.toString().includes(text);
        });
        return { ...question, highlights: updatedHighlights };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    setHighlightDialogOpen(false);
  };

  const handleTimerEnd = () => {
    handleSubmit();
  };


  const handleMouseUp = () => {
    if (!isHighlightNotesSelected) {
      return;
    }
    const selection = window.getSelection();
    if (selection.toString() === '') {
      setHighlightDialogOpen(false);
      return;
    }
    const rect = selection.getRangeAt(0).getBoundingClientRect();
    setHighlightDialogPosition({
      top: rect.top + window.scrollY - 80,
      left: rect.left + window.scrollX,
    });
    setHighlightDialogOpen(true);
  };

  const handleHighlightDialogClose = () => {
    setHighlightDialogOpen(false);
  };

  const renderContent = () => {
    if (!Array.isArray(currentQuestion.content)) {
      return null;
    }

    const applyHighlights = (text, highlights) => {
      let highlightedText = text;
      highlights.forEach((highlight) => {
        const { color, style, text } = highlight;
        const spanStyle = color ? `background-color: ${color};` : `text-decoration: underline ${style};`;
        const highlightedSpan = `<span style="${spanStyle}">${text}</span>`;
        highlightedText = highlightedText.replace(text, highlightedSpan);
      });
      return highlightedText;
    };


    return (
      <div style={{ position: 'relative' }} onMouseUp={handleMouseUp}>
        {currentQuestion.content.map((item, index) => {
          if (item.type === 'text') {
            const highlightedContent = applyHighlights(item.value, currentQuestion.highlights || []);
            return (
              <div key={index} className="prose">
                <div dangerouslySetInnerHTML={{ __html: highlightedContent }} />
              </div>
            );
          } else if (item.type === 'image') {
            return (
              <img
                key={index}
                src={item.value}
                alt={`content-${index}`}
                onLoad={(e) => {
                  const imgElement = e.target;
                  imgElement.style.width = `${imgElement.naturalWidth}px`;
                  imgElement.style.height = `${imgElement.naturalHeight}px`;
                }}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  border: '1px solid #ddd',
                  padding: '5px',
                  backgroundColor: '#f9f9f9',
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };


  const handleOptionSelect = (optionKey) => {
    if (selectedOption === optionKey) {
      setSelectedOption('');
      const updatedQuestions = questions.map((question, index) => {
        if (index === currentIndex) {
          return { ...question, selectedOption: '' };
        }
        return question;
      });
      setQuestions(updatedQuestions);
    } else {
      setSelectedOption(optionKey);
      const updatedQuestions = questions.map((question, index) => {
        if (index === currentIndex) {
          return { ...question, selectedOption: optionKey };
        }
        return question;
      });
      setQuestions(updatedQuestions);
    }
  };

  const handleShortAnswerChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    const updatedQuestions = questions.map((question, index) => {
      if (index === currentIndex) {
        return { ...question, selectedOption: value };
      }
      return question;
    });
    setQuestions(updatedQuestions);
  };

  const leftPaperWidth = maximizeLeft ? 7 : maximizeRight ? 3 : 5;
  const rightPaperWidth = maximizeRight ? 7 : maximizeLeft ? 3 : 5;

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'black' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Container sx={{ display: 'flex', flexDirection: 'column', height: '100vh', minWidth: '100vw' }}>
          <Grid container sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{ py: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ width: '33.3%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography
                  variant="h6"
                  color="black"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1rem', lg: '1.5rem' }, // lg 이하에서 폰트 크기 축소
                    textAlign: { xs: 'center', lg: 'left' }, // lg 이하에서 중앙 정렬
                  }}
                >
                  Mock Test: {mockTest.quizTitle}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                  <Button
                    variant="outlined"
                    size='small'
                    color="primary"
                    onClick={handleDirectionsDialogOpen}
                    sx={{
                      borderRadius: '20px',
                      borderColor: 'white',
                      color: 'black',
                      '&:hover': { borderColor: 'white' }
                    }}
                    ref={directionsButtonRef}
                  >
                    Directions {directionsDialogOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                  <Dialog
                    open={directionsDialogOpen}
                    onClose={handleDirectionsDialogClose}
                    PaperProps={{
                      style: {
                        position: 'absolute',
                        top: directionsButtonRef.current?.getBoundingClientRect().bottom + 10,
                        left: directionsButtonRef.current?.getBoundingClientRect().left,
                        margin: 0,
                      }
                    }}
                  >
                    <DialogTitle>Directions</DialogTitle>
                    <DialogContent>
                      {category === 'Math' ? <MathDirection /> : <EnglishDirection />}
                    </DialogContent>
                    <DialogActions>
                      <Button variant='outlined' onClick={handleDirectionsDialogClose} size='small'
                        sx={{
                          borderColor: 'black', bgcolor: 'gold', borderRadius: '20px',
                          borderWidth: '2px', '&:hover': { borderWidth: '2px', borderColor: 'black', bgcolor: '#FF9100', color: 'white' }
                        }}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Box>
              <Box sx={{ width: '33.3%', display: 'flex', justifyContent: 'center' }}>
                <Stopwatch timer={timer} setTimer={setTimer} isTimerRunning={isTimerRunning} toggleTimer={toggleTimer} resetTimer={resetTimer} onTimeEnd={handleTimerEnd} />
              </Box>
              <Box sx={{ width: '33.3%', display: 'flex', justifyContent: 'flex-end' }}>
                {category === 'Math' && (<Box sx={{ display: 'flex' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <SuperscriptSharpIcon sx={{ mb: '-5px' }} />
                    <Button
                      variant="outlined"
                      size='small'
                      color="primary"
                      onClick={handleRefSheetsDialogOpen}
                      sx={{
                        borderRadius: '20px',
                        borderColor: 'white',
                        color: 'black',
                        '&:hover': { borderColor: 'white' },
                        fontSize: '0.6rem'
                      }}
                      ref={refsheetsButtonRef}
                    >
                      References {refsheetsDialogOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <DesmosCalculator />
                  </Box>
                  <Dialog
                    open={refsheetsDialogOpen}
                    onClose={handleRefSheetsDialogClose}
                    PaperProps={{
                      style: {
                        position: 'absolute',
                        top: refsheetsButtonRef.current?.getBoundingClientRect().bottom + 10,
                        left: refsheetsButtonRef.current?.getBoundingClientRect().left - 100,
                        margin: 0,
                      }
                    }}
                  >
                    <DialogTitle>References</DialogTitle>
                    <DialogContent>
                      <MathRefSheet />
                    </DialogContent>
                    <DialogActions>
                      <Button variant='outlined' onClick={handleRefSheetsDialogClose} size='small'
                        sx={{
                          borderColor: 'black', bgcolor: 'gold', borderRadius: '20px',
                          borderWidth: '2px', '&:hover': { borderWidth: '2px', borderColor: 'black', bgcolor: '#FF9100', color: 'white' }
                        }}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Button
                    onClick={() => setIsHighlightNotesSelected(!isHighlightNotesSelected)}
                    sx={{

                      mt: isHighlightNotesSelected ? '-2px' : 0,
                      borderRadius: 0,
                      borderBottom: isHighlightNotesSelected ? '2px solid black' : 'none',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: 'black' }}>
                      <DriveFileRenameOutlineIcon />
                      <StickyNote2OutlinedIcon />
                    </Box>
                    <Typography variant="caption" sx={{ color: 'black' }}>
                      Highlights & Notes
                    </Typography>
                  </Button>
                  <Button onClick={handleOpen} sx={{ color: 'black', '&:hover': { bgcolor: 'white' } }}>
                    <MoreVertOutlinedIcon />
                  </Button>
                  <DictionarySearch open={open} onClose={handleClose} />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Divider sx={{ width: '100%', height: '3.5px', backgroundColor: 'black' }} />

          <Grid container spacing={2} sx={{ flexGrow: 1, pb: 0.5, mt: 1, mb: 2, justifyContent: 'center', position: 'relative', overflow: { lg: 'auto', xs: 'unset' } }}>
            {!isCheckPage ? (
              <>
                <Grid item xs={12} md={leftPaperWidth}>
                  <Paper
                    elevation={0}
                    sx={{
                      px: 3,
                      height: '100%',
                      display: 'flex',
                      overflow: 'auto',
                      flexDirection: 'column',
                      position: 'relative',
                      minHeight: { xs: '180px', lg: 'auto' }, // lg 이하에서 최소 높이를 300px로 설정
                      overflowY: { xs: 'auto', lg: 'unset' }, // lg 이하에서는 스크롤이 보이지 않도록 설정
                      maxHeight: { xs: '200px', lg: 'unset' } // lg 이하에서는 스크롤이 보이지 않도록 설정
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mx: -3 }}>
                      <IconButton
                        onClick={handleMaximizeLeft}
                        sx={{
                          zIndex: 10,
                          display: { xs: 'none', lg: 'block' }, // lg 이하에서는 보이지 않도록 설정
                        }}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    </Box>
                    {!isTimerRunning && (
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'black',
                        opacity: 1,
                        zIndex: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <Button
                          variant="outlined"
                          onClick={toggleTimer}
                          sx={{
                            color: 'white',
                            borderColor: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            '&:hover': {
                              bgcolor: 'grey',
                              borderColor: 'white',
                            }
                          }}
                        >
                          Start
                        </Button>
                      </Box>
                    )}
                    <Box sx={{ mt: 2, overflow: 'auto', height: '100%' }}>
                      {renderContent()}
                    </Box>
                  </Paper>
                </Grid>

                <Divider
                  orientation={{ xs: 'horizontal', lg: 'vertical' }} // Divider 방향도 lg 이하에서 horizontal로 변경
                  sx={{
                    ml: 2,
                    backgroundColor: 'black',
                    width: { xs: '100%', lg: '3.5px' },
                    height: { xs: '3.5px', lg: '100%' },
                  }}
                />

                <Grid item xs={12} md={rightPaperWidth}>
                  <Paper
                    elevation={0}
                    sx={{
                      px: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto',
                      position: 'relative',
                      minHeight: { xs: '180px', lg: 'unset' },
                      maxHeight: { xs: '200px', lg: 'unset' }
                    }}>
                    {!isTimerRunning && (
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'black',
                        opacity: 1,
                        zIndex: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <Button
                          variant="outlined"
                          onClick={toggleTimer}
                          sx={{
                            color: 'white',
                            borderColor: 'white',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            '&:hover': {
                              bgcolor: 'grey',
                              borderColor: 'white',
                            }
                          }}
                        >
                          Start
                        </Button>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mx: -3 }}>
                      <IconButton
                        onClick={handleMaximizeRight}
                        sx={{
                          zIndex: 10,
                          display: { xs: 'none', lg: 'block' }, // lg 이하에서는 보이지 않도록 설정
                        }}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: 'lightgrey' }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold', bgcolor: 'black', color: 'white', px: '0.5rem' }}>
                        {`${currentIndex + 1}`}
                      </Typography>
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: -0.5 }}>
                        <Button
                          onClick={handleToggleBookmark}
                          sx={{ color: 'black', py: -1, display: 'flex', alignItems: 'center', textTransform: 'none' }}
                        >
                          {currentQuestion.isMarked ? (
                            <BookmarkIcon
                              fontSize="medium"
                              sx={{
                                fill: 'red', // Fill color for the icon
                                stroke: 'black', // Border color for the icon
                                strokeWidth: 1.8 // Width of the border
                              }}
                            />
                          ) : (
                            <BookmarkBorderIcon fontSize="medium" sx={{ color: 'black' }} />
                          )}
                          <Typography sx={{ color: 'black', fontWeight: 'bold', fontSize: '0.8rem', ml: 1 }}>
                            Mark for Review
                          </Typography>
                        </Button>
                        <Button
                          onClick={() => setShowCancelIcon(!showCancelIcon)}
                          sx={{ color: 'black', ml: 2, py: -1 }}
                        >
                          <RuleOutlinedIcon sx={{ color: showCancelIcon ? theme.palette.custom.light : 'black' }} />
                        </Button>
                      </Box>
                    </Box>

                    <Divider sx={{ width: '100%', height: '3.5px', backgroundColor: 'black', mb: 1 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: { lg: '1.2rem', xs: '1.0rem' } }}>
                        {currentQuestion.questiontext}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                      {currentQuestion.questionType === 'short answer' ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, alignItems: 'center', overflow: 'auto' }}>
                          <Box sx={{ borderWidth: '2px', borderColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', width: '80%' }}>
                            <TextField
                              value={selectedOption}
                              onChange={handleShortAnswerChange}
                              variant="standard"
                              fullWidth
                              sx={{
                                mt: 2,
                                px: 2,
                                py: 2,
                                '& .MuiInput-underline:before': {
                                  borderBottom: '2px solid black',
                                },
                                '& .MuiInput-underline:hover:before': {
                                  borderBottom: '2px solid black',
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottom: '2px solid black',
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <List sx={{ display: 'flex', flexDirection: 'column', padding: 0, justifyContent: 'center', overflow: 'auto' }}>
                          {currentQuestion && currentQuestion.options.map((option, index) => (
                            <QuizOption
                              key={index}
                              optionKey={option.name}
                              optionValue={option.value}
                              optionType={option.type}
                              isSelected={selectedOption === option.name}
                              isDisabled={option.isDisabled}
                              onOptionSelect={handleOptionSelect}
                              onToggleDisable={(key) => {
                                const updatedQuestions = questions.map((question, qIndex) => {
                                  if (qIndex === currentIndex) {
                                    const updatedOptions = question.options.map((opt) => {
                                      if (opt.name === key) {
                                        return { ...opt, isDisabled: !opt.isDisabled };
                                      }
                                      return opt;
                                    });
                                    return { ...question, options: updatedOptions };
                                  }
                                  return question;
                                });
                                setQuestions(updatedQuestions);
                              }}
                              showCancelIcon={showCancelIcon}
                            />
                          ))}
                        </List>
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={10}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    position: 'relative'
                  }}>
                  <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      Final Review
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    {questions.map((question, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        onClick={() => handleJumpToQuestion(index)}
                        sx={{
                          margin: 0.3,
                          bgcolor: question.isMarked ? theme.palette.custom.bookmark : question.selectedOption ? theme.palette.custom.dark : 'white',
                          color: question.isMarked || question.selectedOption ? 'white' : theme.palette.custom.dark,
                          aspectRatio: '1/1',
                          fontSize: '1rem',
                          borderColor: theme.palette.custom.dark,
                          borderWidth: '3px',
                          borderRadius: 3,
                          '&:hover': {
                            bgcolor: theme.palette.custom.dark,
                            color: 'white',
                          },
                        }}
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>

          <Divider sx={{ width: '100%', height: '3.5px', backgroundColor: 'black', mb: 2 }} />

          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAttemptLeave}
                sx={{
                  bgcolor: theme.palette.custom.default,
                  borderRadius: '20px',
                  '&:hover': {
                    bgcolor: theme.palette.custom.dark,
                  },
                  fontSize: { xs: '0.6rem', lg: '1rem' }, // lg 이하에서는 글씨 크기 축소
                  padding: { xs: '0.2rem 1rem', lg: '0.2rem 0.8rem' }, // lg 이하에서는 패딩 축소
                }}
              >
                Leave
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleListOpen}
                sx={{
                  bgcolor: theme.palette.custom.default,
                  borderRadius: '20px',
                  '&:hover': {
                    bgcolor: theme.palette.custom.dark,
                  },
                  fontSize: { xs: '0.6rem', lg: '1rem' }, // lg 이하에서는 글씨 크기 축소
                  padding: { xs: '0.2rem 1rem', lg: '0.2rem 0.8rem' }, // lg 이하에서는 패딩 축소
                }}
              >
                List
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
              {currentIndex < questions.length && !isCheckPage ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevQuestion}
                    disabled={currentIndex === 0}
                    sx={{
                      bgcolor: theme.palette.custom.default,
                      borderRadius: '20px',
                      '&:hover': {
                        bgcolor: theme.palette.custom.dark,
                      },
                      fontSize: { xs: '0.6rem', lg: '1rem' }, // lg 이하에서는 글씨 크기 축소
                      padding: { xs: '0.2rem 1rem', lg: '0.2rem 0.8rem' }, // lg 이하에서는 패딩 축소
                    }}
                  >
                    Previous
                  </Button>
                  {currentIndex === questions.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCheck}
                      sx={{
                        bgcolor: theme.palette.custom.default,
                        borderRadius: '20px',
                        '&:hover': {
                          bgcolor: theme.palette.custom.dark,
                        },
                        ml: 2,
                        fontSize: { xs: '0.6rem', lg: '1rem' }, // lg 이하에서는 글씨 크기 축소
                        padding: { xs: '0.2rem 1rem', lg: '0.2rem 0.8rem' }, // lg 이하에서는 패딩 축소
                      }}
                    >
                      Check
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      
                      onClick={handleNextQuestion}
                      sx={{
                        bgcolor: theme.palette.custom.default,
                        borderRadius: '20px',
                        '&:hover': {
                          bgcolor: theme.palette.custom.dark,
                        },
                        ml: 2,
                        fontSize: { xs: '0.6rem', lg: '1rem' }, // lg 이하에서는 글씨 크기 축소
                        padding: { xs: '0.2rem 0.8rem', lg: '0.2rem 0.8rem' }, // lg 이하에서는 패딩 축소
                      }}
                    >
                      Next
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{
                    bgcolor: theme.palette.custom.default,
                    borderRadius: '20px',
                    '&:hover': {
                      bgcolor: theme.palette.custom.dark,
                    },
                    fontSize: { xs: '0.6rem', lg: '1rem' }, // lg 이하에서는 글씨 크기 축소
                    padding: { xs: '0.2rem 1rem', lg: '0.2rem 0.8rem' }, // lg 이하에서는 패딩 축소
                  }}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
          <ConfirmLeaveDialog open={leaveDialogOpen} handleClose={handleLeaveCancel} handleConfirm={handleLeaveConfirm} />

          <Dialog
            open={highlightDialogOpen}
            onClose={handleHighlightDialogClose}
            PaperProps={{
              style: {
                position: 'absolute',
                top: highlightDialogPosition.top,
                left: highlightDialogPosition.left,
                margin: 0,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // 그림자를 작게 설정
                borderRadius: '40px', // 둥근 모서리 설정
              }
            }}
            BackdropProps={{ invisible: true }}
          >
            <DialogContent sx={{ padding: '8px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Tooltip>
                  <Fab size="small" onClick={() => handleHighlightColorChange('yellow')} sx={{ bgcolor: 'yellow', margin: 0.3, border: '2.5px solid black' }}>
                    <HighlightIcon />
                  </Fab>
                </Tooltip>
                <Tooltip>
                  <Fab size="small" onClick={() => handleHighlightColorChange('pink')} sx={{ bgcolor: 'pink', margin: 0.3, border: '2.5px solid black' }}>
                    <HighlightIcon />
                  </Fab>
                </Tooltip>
                <Tooltip>
                  <Fab size="small" onClick={() => handleHighlightColorChange('lightgreen')} sx={{ bgcolor: 'lightgreen', margin: 0.3, border: '2.5px solid black' }}>
                    <HighlightIcon />
                  </Fab>
                </Tooltip>
                <Tooltip>
                  <Select
                    value={underlineStyle}
                    onChange={handleUnderlineStyleChange}
                    displayEmpty
                    size="small"
                    sx={{ margin: 0.3, border: '2.5px solid black' }}
                  >
                    <MenuItem value="solid">
                      <Typography sx={{ borderBottom: '1px solid black', textAlign: 'center' }}>U</Typography>
                    </MenuItem>
                    <MenuItem value="dashed">
                      <Typography sx={{ borderBottom: '1px dashed black', textAlign: 'center' }}>U</Typography>
                    </MenuItem>
                    <MenuItem value="dotted">
                      <Typography sx={{ borderBottom: '1px dotted black', textAlign: 'center' }}>U</Typography>
                    </MenuItem>
                    <MenuItem value="none">
                      <Typography sx={{ textAlign: 'center' }}>None</Typography>
                    </MenuItem>
                  </Select>
                </Tooltip>
                <Tooltip title="Remove Highlights">
                  <Fab size="small" onClick={handleRemoveHighlights} sx={{ margin: 0.3, border: '2.5px solid black' }}>
                    <HighlightOffIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Add memo">
                  <Fab size="small" sx={{ margin: 0.3, border: '2.5px solid black' }}>
                    <StickyNote2OutlinedIcon />
                  </Fab>
                </Tooltip>
              </Box>
            </DialogContent>
          </Dialog>


          <Dialog open={listDialogOpen} onClose={handleListClose}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', px: 2, pt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', flexGrow: 1, textAlign: 'center' }}>
                {mockTest.quizTitle}
              </Typography>
              <DialogActions>
                <IconButton onClick={handleListClose} color='default'>
                  <CloseRoundedIcon />
                </IconButton>
              </DialogActions>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Divider sx={{ width: '90%', height: '2px', backgroundColor: 'lightgrey' }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 1, flexDirection: 'row' }}>
                <Box item sx={{ display: 'flex', flexDirection: 'row', mx: 1 }}>
                  <FmdGoodOutlinedIcon />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                    Current
                  </Typography>
                </Box>
                <Box item sx={{ display: 'flex', flexDirection: 'row', mx: 1 }}>
                  <SquareIcon sx={{ color: theme.palette.custom.dark }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                    Answered
                  </Typography>
                </Box>
                <Box item sx={{ display: 'flex', flexDirection: 'row', mx: 1 }}>
                  <SquareIcon sx={{ color: 'red' }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                    For Review
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: '90%', height: '2px', backgroundColor: 'lightgrey' }} />
            </Box>
            <DialogContent sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {questions.map((question, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handleJumpToQuestion(index)}
                  sx={{
                    margin: 0.4,
                    width: '2.8rem', // Adjust the width to make the buttons smaller
                    height: '2.8rem', // Adjust the height to make the buttons smaller
                    minWidth: '2rem', // Ensure the minimum width
                    bgcolor: question.isMarked ? theme.palette.custom.bookmark : question.selectedOption ? theme.palette.custom.dark : 'white',
                    color: question.isMarked || question.selectedOption ? 'white' : theme.palette.custom.dark,
                    fontSize: '0.75rem', // Adjust the font size to make the text smaller
                    borderColor: theme.palette.custom.dark,
                    borderWidth: '2px',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: theme.palette.custom.dark,
                      color: 'white',
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0, // Remove padding to ensure consistent size
                  }}
                >
                  {index + 1}
                </Button>
              ))}
            </DialogContent>
          </Dialog>
        </Container>
      )}
    </ThemeProvider>
  );
}

export default MockTestPage;
