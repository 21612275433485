import React, { useState, useRef, useEffect } from 'react';
import { GraphingCalculator } from 'desmos-react';
import CalculateIcon from '@mui/icons-material/Calculate'; // MUI 계산기 아이콘
import Button from '@mui/material/Button'; // MUI Button
import Box from '@mui/material/Box'; // MUI Box for positioning
import Typography from '@mui/material/Typography'; // MUI Typography

const DesmosCalculator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [calculatorSize, setCalculatorSize] = useState({ width: 500, height: 500 });
  const buttonRef = useRef(null);

  const updateCalculatorPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  };

  const updateCalculatorSize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const width = Math.min(500, windowWidth * 0.8); // 최대 500px, 창 너비의 80%
    const height = Math.min(500, windowHeight * 0.6); // 최대 500px, 창 높이의 60%

    setCalculatorSize({ width, height });
  };

  useEffect(() => {
    if (isVisible) {
      updateCalculatorPosition();
      updateCalculatorSize();

      window.addEventListener('resize', updateCalculatorPosition);
      window.addEventListener('resize', updateCalculatorSize);
    }

    return () => {
      window.removeEventListener('resize', updateCalculatorPosition);
      window.removeEventListener('resize', updateCalculatorSize);
    };
  }, [isVisible]);

  const toggleCalculator = () => {
    if (!isVisible) {
      updateCalculatorPosition();
      updateCalculatorSize();
    }
    setIsVisible(!isVisible);
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button
        onClick={toggleCalculator}
        variant="outlined"
        size="small"
        color="primary"
        ref={buttonRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          color: 'black',
          borderColor: 'white',
          boxShadow: 'none',
          '&:hover': { borderColor: 'white' },
        }}
      >
        <CalculateIcon sx={{ fontSize: 32 }} />
        <Typography variant="caption" sx={{ color: 'black' }}>
          Calculator
        </Typography>
      </Button>
      {isVisible && (
        <Box
          sx={{
            position: 'absolute',
            top: position.top,
            left: position.left,
            transform: 'translate(-50%, 0)',
            width: calculatorSize.width,
            height: calculatorSize.height,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            backgroundColor: 'white',
            zIndex: 1000,
            padding: 2,
          }}
        >
          <GraphingCalculator
            attributes={{ id: 'calculator', className: 'calculator' }}
            fontSize={13}
            keypad
          />
        </Box>
      )}
    </Box>
  );
};

export default DesmosCalculator;