
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom'; // useNavigate 훅 사용
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BookmarkIcon from '@mui/icons-material/Bookmark';

function ClassList({ classes }) {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const handleQuizButtonClick = (classID, className) => {
    navigate(`/classes/${classID}/lectures`, { state: { classID, className } });
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0f3e8e',
      },
      custom: {
        light: '#4f7aa2',
        dark: '#0a2b4d',
        default: '#0f3e8e',
        darker: '#0a1e3c',
        lightest: '#f0f0f0',
        bookmark: '#d32f2f',
        black: '#000000'
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {classes.map((classItem) => (
        <Box
          key={classItem.classID}
          sx={{
            border: '1px solid #ddd',
            borderRadius: '8px',
            marginBottom: '16px',
            padding: '16px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <BookmarkIcon sx={{ color: '#f0a202', marginRight: '8px' }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
              {classItem.className}
            </Typography>
            <Typography variant="body2" sx={{ color: '#999' }}>
              No.{classItem.classID}
            </Typography>
          </Box>
          <Divider sx={{ mb: 2, height: "1.5px", backgroundColor: 'black' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <Typography variant="body2" sx={{ color: '#555' }}>
              {classItem.description}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              onClick={() => handleQuizButtonClick(classItem.classID, classItem.className)}
              sx={{
                bgcolor: theme.palette.custom.lightest, // 배경색
                '&:hover': {
                  bgcolor: 'gray.300', // 호버 시 배경색
                },
                transition: 'background-color 0.15s ease-in-out', // 전환 효과
                px: 4, // padding-left, padding-right
                py: 2, // padding-top, padding-bottom
                borderRadius: '8px', // 모서리 둥글게
                outline: 'none', // 포커스 시 아웃라인 제거
              }}
            >
              <Typography variant="body" component="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
                강의 자세히 보기
              </Typography>
            </Button>
          </Box>
          <Divider sx={{ mt: 2, height: "1px", backgroundColor: 'black' }} />

        </Box>
      ))}
    </ThemeProvider>
  );
}

export default ClassList;
