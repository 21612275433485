import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import StorageItems from "./StorageItems";
import StorageUpperButtons from "./StorageUpperButtons";
import StorageFolderModal from "./StorageFolderModal";
import BoardCommonHead from "../Board/BoardCommonHead";
import { useApi } from "../../../contexts/Apicontext";
import Loading from "../../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";


const StorageComponents = () => {
  const [mainFolders, setMainFolders] = useState([]); // 메인 폴더 목록
  const [subLists, setSubLists] = useState({ folders: [], files: [] }); // 하위 폴더 및 파일 목록
  const [currentFolderId, setCurrentFolderId] = useState(0); // 현재 폴더 ID
  const [currentPath, setCurrentPath] = useState("Home"); // 현재 경로
  const [prevFolderId, setPrevFolderId] = useState([]); // 상위 폴더 ID 기록
  const [folderNameModal, setFolderNameModal] = useState(false); // 폴더 생성 모달 상태
  const [searchKeyword, setSearchKeyword] = useState(""); // 검색 키워드
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const fileInputRef = useRef(null); // 파일 업로드 input 참조
  const apiBaseUrl = useApi();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const user = useSelector(state => state.auth.user);



  // 메인 폴더 데이터를 가져옵니다.
  const fetchMainFolders = async () => {
    setLoading(true); // 로딩 상태 시작
    try {
      const response = await fetch(`${apiBaseUrl}/storage/main`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch main folders.");
      const data = await response.json();
      console.log("data", data);
      setSubLists(data);
      setMainFolders(data.folders);
    } catch (error) {
      console.error("Error fetching main folders:", error);
    } finally {
      setLoading(false); // 로딩 상태 종료
    }
  };


  // 폴더 상세 데이터 가져오기
  const fetchFolderDetails = async (folderId, keyWord = "") => {
    setLoading(true); // 로딩 상태 시작
    try {
      const params = new URLSearchParams({
        parentsFolderId: folderId || "",
        keyWord: keyWord || "",
      });
      const response = await fetch(`${apiBaseUrl}/storage/details?${params}`);
      if (!response.ok) throw new Error("Failed to fetch folder details.");
      const data = await response.json();
      console.log("data2", data);
      setSubLists(data);
    } catch (error) {
      console.error("Error fetching folder details:", error);
    } finally {
      setLoading(false); // 로딩 상태 종료
    }
  };

  // Delete folder
  const deleteFolder = async (folderId) => {
    const token = localStorage.getItem("token");
    if (window.confirm("정말 이 폴더를 삭제하시겠습니까?")) {
      try {
        await fetch(`${apiBaseUrl}/storage/folder/${folderId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (currentFolderId === 0) {
          fetchMainFolders(); // 메인 폴더 조회
        } else {
          fetchFolderDetails(currentFolderId); // 상세 폴더 조회
        }
      } catch (error) {
        console.error("Error deleting folder:", error);
      }
    }
  };

  // Delete file
  const deleteFile = async (fileId) => {
    const token = localStorage.getItem("token");
    if (window.confirm("정말 이 파일을 삭제하시겠습니까?")) {
      try {
        await fetch(`${apiBaseUrl}/storage/file/${fileId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (currentFolderId === 0) {
          fetchMainFolders(); // 메인 폴더 조회
        } else {
          fetchFolderDetails(currentFolderId); // 상세 폴더 조회
        }
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    }
  };


  // 폴더 클릭 시
  const onClickFolder = (folderId, folderName) => {
    setPrevFolderId((prev) => [...prev, currentFolderId]);
    setCurrentFolderId(folderId);
    setCurrentPath(`${currentPath}/${folderName}`);
    fetchFolderDetails(folderId);
  };

  // 상위 폴더 이동
  const onClickUpFolder = () => {
    if (prevFolderId.length === 0) return;
    const newFolderId = prevFolderId.pop();
    setCurrentFolderId(newFolderId);
    setCurrentPath((prev) => prev.substring(0, prev.lastIndexOf("/")));
    // 폴더 생성 후 현재 상태에 맞게 데이터를 다시 가져옵니다.
    if (newFolderId === 0) {
      fetchMainFolders(); // 메인 폴더 조회
    } else {
      fetchFolderDetails(newFolderId); // 상세 폴더 조회
    }
  };

  const onSubmitCreateFolder = async (folderName) => {
    try {
      const status = currentFolderId === 0 ? "MAIN" : "SUB";

      const token = localStorage.getItem("token");
      await fetch(`${apiBaseUrl}/storage/folder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: folderName,
          parentsFolderId: currentFolderId,
        }),
      });

      // 폴더 생성 후 현재 상태에 맞게 데이터를 다시 가져옵니다.
      if (currentFolderId === 0) {
        fetchMainFolders(); // 메인 폴더 조회
      } else {
        fetchFolderDetails(currentFolderId); // 상세 폴더 조회
      }
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const onFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const formData = new FormData();

    // Encode file name to avoid issues with non-ASCII characters
    const encodedFileName = encodeURIComponent(file.name);

    // Append encoded file name to formData
    formData.append("file", file, encodedFileName);

    const url = new URL(`${apiBaseUrl}/storage/file`);
    url.searchParams.append("parentsFolderId", currentFolderId);
    const token = localStorage.getItem("token");

    try {
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      // Reload folder details
      if (currentFolderId === 0) {
        fetchMainFolders();
      } else {
        fetchFolderDetails(currentFolderId);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const onClickFile = (fileUrl) => {
    console.log("fileUrl", fileUrl);
    const newWindow = window.open(fileUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (currentFolderId === 0) fetchMainFolders();
    else fetchFolderDetails(currentFolderId);
  }, [currentFolderId]);


  return (
    <Box sx={{ padding: 3 }}>
      {/* 검색 및 경로 */}
      <BoardCommonHead
        searchKeyword={searchKeyword}
        setSearching={(searching) => {
          if (searching) fetchFolderDetails("", searchKeyword);
        }}
        modal={0}
        placeholder="자료실 검색"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, width: "70%", mx: "auto" }}>
        <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
          {currentPath}
        </Typography>
        {/* 상단 버튼 */}
        <StorageUpperButtons
          onClickUpFolder={onClickUpFolder}
          onClickCreateFolder={() => setFolderNameModal(true)}
          onFileUpload={() => fileInputRef.current.click()}
          isRootFolder={currentFolderId === 0} // Pass a prop to indicate root folder
        />
      </Box>
      {/* 폴더 및 파일 목록 */}
      {!isAuthenticated ? (
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            color: "gray",
            mt: 4,
          }}
        >
          로그인이 필요한 항목입니다.
        </Typography>
      ) : loading ? (
        <Loading />
      ) : (
        <Grid container direction="column" alignItems="center" spacing={1}>
          {subLists.folders?.map((folder) => (
            <Grid
              item
              key={`folder-${folder.folderID}`}
              sx={{
                width: "70%",
              }}
            >
              <StorageItems
                item={folder}
                type="folder"
                onClick={() => onClickFolder(folder.folderID, folder.name)}
                onDelete={() => deleteFolder(folder.folderID)} // Pass delete logic
                isAdmin={user?.isAdmin}
              />
            </Grid>
          ))}
          {subLists.files?.map((file) => (
            <Grid
              item
              key={`file-${file.fileID}`}
              sx={{
                width: "70%",
              }}
            >
              <StorageItems
                item={file}
                type="file"
                onClick={() => onClickFile(file.file_url)}
                onDelete={() => deleteFile(file.fileID)} // Pass delete logic
                isAdmin={user?.isAdmin}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {/* 폴더 생성 모달 */}
      <StorageFolderModal
        open={folderNameModal}
        onClose={() => setFolderNameModal(false)}
        onSubmit={onSubmitCreateFolder}
      />

      {/* 파일 업로드 Input */}
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onFileUpload}
      />
    </Box>
  );
};

export default StorageComponents;