import React, { useState, useEffect, memo } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, Select, MenuItem, Autocomplete
} from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AWS from 'aws-sdk';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillEditorStyles.css'; // 스타일 파일을 import 합니다.
import { useApi } from '../../contexts/Apicontext';
import { useLocation, useNavigate } from 'react-router-dom';
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;



// AWS S3 설정
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();

const uploadFileToS3 = (file, quizID, order, imageIndex, optionName) => {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `${quizID}/${order}/option-${optionName}-${imageIndex}-${file.name}`, // S3에 저장될 파일 이름
        Body: file
    };

    return s3.upload(params).promise();
};

const QuizQuestionsPage = ({ onPageChange }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newQuestion, setNewQuestion] = useState({
        order: '', difficulty: '', content: [{ type: 'text', value: '' }], questiontext: '', options: [
            { type: 'text', name: 'A', value: '' },
            { type: 'text', name: 'B', value: '' },
            { type: 'text', name: 'C', value: '' },
            { type: 'text', name: 'D', value: '' }
        ], answer: '', questionType: '', explanation: '', tags: []
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedImageCount, setUploadedImageCount] = useState(0);
    const [orderError, setOrderError] = useState('');
    const [difficultyError, setDifficultyError] = useState(false);
    const [tags, setTags] = useState([]);
    const [detailedTags, setDetailedTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedDetailedTag, setSelectedDetailedTag] = useState(null);
    const apiBaseUrl = useApi();
    const [showImageOnly, setShowImageOnly] = useState(location.state.showImageOnly || false);

    const { quizID, page, rowsPerPage } = location.state || {};

    // showImageOnly 상태에 따라 질문을 필터링
    const filteredQuestions = showImageOnly
        ? questions.filter(question =>
            question.content.some(item => item.type === "image")
        )
        : questions;

    useEffect(() => {
        fetchQuestions();
        fetchTags();
    }, [quizID]);

    useEffect(() => {
        console.log('questions', questions);
    }, [questions]);


    const fetchQuestions = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes/${quizID}/questions`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();

        // Order 순으로 정렬
        data.sort((a, b) => a.order - b.order);

        setQuestions(data);
    };

    const fetchTags = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setTags(data);
    };

    const fetchDetailedTags = async (tagID) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags/${tagID}/detailed`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setDetailedTags(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewQuestion({
            order: '', difficulty: '', content: [{ type: 'text', value: '' }], questiontext: '', options: [
                { type: 'text', name: 'A', value: '' },
                { type: 'text', name: 'B', value: '' },
                { type: 'text', name: 'C', value: '' },
                { type: 'text', name: 'D', value: '' }
            ], answer: '', questionType: '', explanation: '', tags: []
        });
        setSelectedFiles([]);
        setOrderError('');
        setDifficultyError(false);
        setSelectedTag(null);
        setSelectedDetailedTag(null);
    };

    const handleAddQuestion = async () => {
        if (!newQuestion.difficulty) {
            setDifficultyError(true);
            return;
        }

        let updatedContent = [...newQuestion.content];
        let updatedOptions = [...newQuestion.options];
        // 파일이 선택된 경우 S3에 업로드하고 URL을 content에 추가
        for (let i = 0; i < selectedFiles.length; i++) {
            if (selectedFiles[i] && true) {
                try {

                    const uploadResult = await uploadFileToS3(selectedFiles[i], quizID, newQuestion.order, uploadedImageCount + i + 1, newQuestion.options[i]?.name || 'content');
                    if (i < newQuestion.content.length) {
                        updatedContent[i].value = uploadResult.Location;
                    } else {
                        updatedOptions[i - newQuestion.content.length].value = uploadResult.Location;
                    }
                } catch (error) {
                    alert('Failed to upload image.');
                    return;
                }
            }
        }

        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes/${quizID}/questions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ ...newQuestion, 
                content: updatedContent, 
                options: newQuestion.questionType === 'short answer' ? [] : updatedOptions, 
                tags: [selectedTag, selectedDetailedTag],
                memo: newQuestion.memo || ''})
        });

        if (response.ok) {
            fetchQuestions();
            handleCloseAddDialog();
        } else {
            alert('Failed to add new question.');
        }
    };

    const handleNewQuestionInputChange = (e) => {
        const { name, value } = e.target;
        setNewQuestion({ ...newQuestion, [name]: value });

        if (name === 'order') {
            const orderExists = questions.some(question => question.order === parseInt(value, 10));
            if (orderExists) {
                setOrderError('Order already exists.');
            } else {
                setOrderError('');
            }
        }
    };

    const handleDifficultyChange = (e) => {
        const difficultyMapping = {
            '하': 3,
            '중': 2,
            '상': 1
        };
        const selectedDifficulty = e.target.value;
        setNewQuestion({ ...newQuestion, difficulty: selectedDifficulty ? difficultyMapping[selectedDifficulty] : '' });
        setDifficultyError(!selectedDifficulty); // If difficulty is not set, set error
    };

    const handleNewContentChange = (index, field, value) => {
        const newContent = [...newQuestion.content];
        newContent[index][field] = value;
        setNewQuestion({ ...newQuestion, content: newContent });
    };

    const handleAddContent = () => {
        setNewQuestion({
            ...newQuestion,
            content: [...newQuestion.content, { type: 'text', value: '' }]
        });
    };

    const handleRemoveContent = (index) => {
        const newContent = [...newQuestion.content];
        newContent.splice(index, 1);
        setNewQuestion({ ...newQuestion, content: newContent });
    };

    const handleNewOptionChange = (index, field, value) => {
        const newOptions = [...newQuestion.options];
        newOptions[index][field] = value;
        setNewQuestion({ ...newQuestion, options: newOptions });
    };

    const handleAddOption = () => {
        setNewQuestion({
            ...newQuestion,
            options: [...newQuestion.options, { type: 'text', name: '', value: '' }]
        });
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...newQuestion.options];
        newOptions.splice(index, 1);
        setNewQuestion({ ...newQuestion, options: newOptions });
    };

    const handleFileChange = (e, index) => {
        const file = e.target.files[0];
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles[index] = file;
        setSelectedFiles(newSelectedFiles);

    };

    const handleTagChange = (event, newValue) => {
        setSelectedTag(newValue);
        if (newValue) {
            fetchDetailedTags(newValue.tagID);
        } else {
            setDetailedTags([]);
            setSelectedDetailedTag(null);
        }
    };

    const handleDetailedTagChange = (event, newValue) => {
        setSelectedDetailedTag(newValue);
    };

    return (
        <div className='w-full'>
            <IconButton onClick={() => onPageChange(`quizzes`, { page: page, rowsPerPage: rowsPerPage })} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Questions for Quiz {quizID}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpenAddDialog}
                >
                    Add New Question
                </Button>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showImageOnly}
                            onChange={(e) => setShowImageOnly(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={showImageOnly ? "모든 문제 보기" : "이미지 포함 문제만 보기"}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredQuestions.map((question) => (
                            <TableRow key={question.order}>
                                <TableCell>{question.order}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onPageChange(`quizzes/${quizID}/questions/${question.questionID}`, { quizID: quizID, questionID: question.questionID, questions: filteredQuestions, showImageOnly: showImageOnly })}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="lg">
                <DialogTitle>Add New Question</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new question.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="order"
                        label="Order"
                        value={newQuestion.order}
                        onChange={handleNewQuestionInputChange}
                        fullWidth
                        error={!!orderError}
                        helperText={orderError}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Difficulty</Typography>
                        <Select
                            name="difficulty"
                            value={newQuestion.difficulty ? (newQuestion.difficulty === 3 ? '하' : newQuestion.difficulty === 2 ? '중' : '상') : ''}
                            onChange={handleDifficultyChange}
                            fullWidth
                            error={difficultyError}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>난이도 선택 필수</MenuItem>
                            <MenuItem value="상">상</MenuItem>
                            <MenuItem value="중">중</MenuItem>
                            <MenuItem value="하">하</MenuItem>
                        </Select>
                        {difficultyError && <Typography color="error">This field is required</Typography>}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Question Type</Typography>
                        <Select
                            name="questionType"
                            value={newQuestion.questionType}
                            onChange={(e) => handleNewQuestionInputChange(e)}
                            fullWidth
                        >
                            <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                            <MenuItem value="short answer">Short Answer</MenuItem>
                        </Select>
                    </Box>
                    <TextField
                        margin="dense"
                        name="questiontext"
                        label="Question Text"
                        value={newQuestion.questiontext}
                        onChange={handleNewQuestionInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Content</Typography>
                        {newQuestion.content.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleNewContentChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {item.type === 'text' ? (
                                    <ReactQuill
                                        value={item.value}
                                        onChange={(content) => handleNewContentChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => handleFileChange(e, index)}
                                        />
                                        {selectedFiles[index] && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography variant="body1"><strong>Selected File:</strong></Typography>
                                                <Typography variant="body2">{selectedFiles[index].name}</Typography>
                                                <img src={URL.createObjectURL(selectedFiles[index])} alt="Selected file" style={{ maxWidth: '100%', marginTop: '10px' }} />
                                            </Box>
                                        )}
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveContent(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddContent} color="primary" startIcon={<AddIcon />}>
                            Add Content
                        </Button>
                    </Box>
                    {newQuestion.questionType !== 'short answer' && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">Options</Typography>
                            {newQuestion.options.map((option, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                    <Select
                                        value={option.type}
                                        onChange={(e) => handleNewOptionChange(index, 'type', e.target.value)}
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuItem value="text">Text</MenuItem>
                                        <MenuItem value="image">Image</MenuItem>
                                    </Select>
                                    <TextField
                                        label="Name"
                                        value={option.name}
                                        onChange={(e) => handleNewOptionChange(index, 'name', e.target.value)}
                                        sx={{ mr: 2 }}
                                    />
                                    {option.type === 'text' ? (
                                        <ReactQuill
                                            value={option.value}
                                            onChange={(content) => handleNewOptionChange(index, 'value', content)}
                                            modules={{
                                                toolbar: [
                                                    [{ 'size': [] }, { 'font': [] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                    ['clean'], [{ 'formula': 'formula' }],
                                                    [{ 'color': [] }, { 'background': [] }],
                                                ],
                                                clipboard: {
                                                    matchVisual: false,
                                                },
                                            }}
                                            formats={[
                                                'size', 'font',
                                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                            ]}
                                            className="prose quill-editor"
                                        />
                                    ) : (
                                        <>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => handleFileChange(e, newQuestion.content.length + index)}
                                            />
                                            {selectedFiles[newQuestion.content.length + index] && (
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography variant="body1"><strong>Selected File:</strong></Typography>
                                                    <Typography variant="body2">{selectedFiles[newQuestion.content.length + index].name}</Typography>
                                                    <img src={URL.createObjectURL(selectedFiles[newQuestion.content.length + index])} alt="Selected file" style={{ maxWidth: '100%', marginTop: '10px' }} />
                                                </Box>
                                            )}
                                        </>
                                    )}
                                    <IconButton onClick={() => handleRemoveOption(index)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button onClick={handleAddOption} color="primary" startIcon={<AddIcon />}>
                                Add Option
                            </Button>
                        </Box>
                    )}
                    <TextField
                        margin="dense"
                        name="answer"
                        label="Answer"
                        value={newQuestion.answer}
                        onChange={handleNewQuestionInputChange}
                        fullWidth
                    />

                    <TextField
                        margin="dense"
                        name="explanation"
                        label="Explanation"
                        value={newQuestion.explanation}
                        onChange={handleNewQuestionInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="memo"
                        label="Memo"
                        value={newQuestion.memo || ''}
                        onChange={handleNewQuestionInputChange}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Tags</Typography>
                        <Autocomplete
                            options={tags}
                            getOptionLabel={(option) => option.tagName}
                            value={selectedTag}
                            onChange={handleTagChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Tag"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Box>
                    {selectedTag && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">Detailed Tags</Typography>
                            <Autocomplete
                                options={detailedTags}
                                getOptionLabel={(option) => option.detailedTagName}
                                value={selectedDetailedTag}
                                onChange={handleDetailedTagChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Detailed Tag"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddQuestion} color="primary" disabled={!!orderError || !newQuestion.difficulty || !newQuestion.questionType}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuizQuestionsPage;
