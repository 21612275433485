import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";

const ArchiveFolderModal = ({ open, onClose, onSubmit }) => {
  const [folderName, setFolderName] = useState("");

  const handleSubmit = () => {
    if (folderName.trim()) {
      onSubmit(folderName); // 부모 컴포넌트로 폴더 이름 전달
      setFolderName(""); // 폴더 이름 초기화
      onClose(); // 모달 닫기
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          새 폴더 생성
        </Typography>
        <TextField
          label="폴더 이름"
          fullWidth
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            취소
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            생성
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ArchiveFolderModal;