import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, Autocomplete } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../contexts/Apicontext';
import Swal from 'sweetalert2';
import Collapse from '@mui/material/Collapse';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const UserDetailPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = location.state?.user || {};
    const userID = useParams().userID;
    const apiBaseUrl = useApi();
    const [classes, setClasses] = useState([]);
    const [availableClasses, setAvailableClasses] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newClass, setNewClass] = useState({ classID: '', enrollmentDate: new Date().toISOString().split('T')[0] });
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedClassID, setSelectedClassID] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editEnrollmentDate, setEditEnrollmentDate] = useState('');
    const [expandedClassID, setExpandedClassID] = useState(null);
    const [lectures, setLectures] = useState({});
    const [loadingLecture, setLoadingLecture] = useState(false);


    useEffect(() => {
        fetchUserClasses();
        fetchAvailableClasses();
    }, [user.userID]);

    const fetchUserClasses = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users/${user.userID}/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setClasses(data);
    };

    const demo = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes/words-in-context`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    };


    const fetchAvailableClasses = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/classes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setAvailableClasses(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewClass({ classID: '', enrollmentDate: new Date().toISOString().split('T')[0] });
        setSelectedClass(null);
    };

    const handleAddClass = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users/${user.userID}/classes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ classID: selectedClass.classID, enrollmentDate: newClass.enrollmentDate })
        });

        if (response.ok) {
            Swal.fire('Added!', 'Class has been added to the user.', 'success');
            fetchUserClasses();
            handleCloseAddDialog();
        } else {
            Swal.fire('Error!', 'Failed to add class to the user.', 'error');
        }
    };

    const handleEditClick = (classItem) => {
        setSelectedClassID(classItem.userClassID);
        setEditEnrollmentDate(classItem.enrollmentDate);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedClassID(null);
        setEditEnrollmentDate('');
    };

    const handleSaveEdit = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/user-classes/${selectedClassID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ enrollmentDate: editEnrollmentDate })
        });

        if (response.ok) {
            Swal.fire('Saved!', 'Enrollment date has been updated.', 'success');
            fetchUserClasses();
            handleCloseEditDialog();
        } else {
            Swal.fire('Error!', 'Failed to update enrollment date.', 'error');
        }
    };

    const handleDeleteClick = async (userClassID) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/user-classes/${userClassID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            Swal.fire('Deleted!', 'Class has been removed from the user.', 'success');
            fetchUserClasses();
        } else {
            Swal.fire('Error!', 'Failed to remove class from the user.', 'error');
        }
    };

    const handleExpandClick = async (classID) => {
        if (expandedClassID === classID) {
            // Collapse if the same row is clicked
            setExpandedClassID(null);
            return;
        }

        // Set loading and fetch lectures
        setLoadingLecture(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/classes/${classID}/lectures`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            console.log(data);

            // Format the data to match the desired structure
            const formattedLectures = data.map((lecture) => ({
                lectureID: lecture.lectureID,
                title: lecture.title,
                videoURL: lecture.videoURL || '.', // Default to "." if missing
                resources: lecture.resources || '.', // Default to "." if missing
            }));

            setLectures((prev) => ({
                ...prev,
                [classID]: formattedLectures,
            }));
        } catch (error) {
            console.error('Failed to fetch lectures:', error);
        } finally {
            setLoadingLecture(false);
        }

        // Expand the clicked row
        setExpandedClassID(classID);
    };

    return (
        <Box sx={{ p: 3, width: '100%' }}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)} sx={{ mb: 3 }}>
                Back
            </Button>
            <Paper sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>User Details</Typography>
                <Typography variant="body1"><strong>Name:</strong> {user.name}</Typography>
                <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
                <Typography variant="body1"><strong>Password:</strong> {user.password}</Typography>
                <Typography variant="body1"><strong>Role:</strong> {user.role}</Typography>
                <Typography variant="body1"><strong>Is Admin:</strong> {user.isAdmin ? 'True' : 'False'}</Typography>
                <Typography variant="body1"><strong>Signup Date:</strong> {new Date(user.signupDate).toLocaleString()}</Typography>
                <Typography variant="body1"><strong>Last Login:</strong> {new Date(user.lastLogin).toLocaleString()}</Typography>
            </Paper>
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6">Classes</Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog} sx={{ mb: 2 }}>
                    Add Class
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Class Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Enrollment Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {classes.map((classItem) => (
                                <React.Fragment key={classItem.classID}>
                                    <TableRow>
                                        <TableCell>{classItem.className}</TableCell>
                                        <TableCell>{classItem.description}</TableCell>
                                        <TableCell>
                                            {new Date(classItem.enrollmentDate).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleExpandClick(classItem.classID)}
                                            >
                                                {expandedClassID === classItem.classID ? (
                                                    <KeyboardArrowUp />
                                                ) : (
                                                    <KeyboardArrowDown />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    {/* Expandable Row */}
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            style={{
                                                paddingBottom: 0,
                                                paddingTop: 0,
                                                borderBottom: expandedClassID === classItem.classID ? '1px solid #ddd' : 'none',
                                            }}
                                        >
                                            <Collapse
                                                in={expandedClassID === classItem.classID}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <Box sx={{ margin: 2 }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginBottom: 1,
                                                        }}
                                                    >
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            Lectures
                                                        </Typography>
                                                        <Box>
                                                            {/* Edit 버튼 */}
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                startIcon={<EditIcon />}
                                                                sx={{ marginRight: 1 }}
                                                                onClick={() => handleEditClick(classItem)} // Class 수정 다이얼로그 열기
                                                            >
                                                                Edit Class
                                                            </Button>
                                                            {/* Delete 버튼 */}
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                startIcon={<DeleteIcon />}
                                                                onClick={() => handleDeleteClick(classItem.classID)} // Class 삭제
                                                            >
                                                                Delete Class
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    {loadingLecture && expandedClassID === classItem.classID ? (
                                                        <CircularProgress size={24} />
                                                    ) : lectures[classItem.classID] && lectures[classItem.classID].length > 0 ? (
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Lecture ID</TableCell>
                                                                    <TableCell>Title</TableCell>
                                                                    <TableCell>Video URL</TableCell>
                                                                    <TableCell>Resources</TableCell>
                                                                    <TableCell>Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {lectures[classItem.classID].map((lecture) => (
                                                                    <TableRow key={lecture.lectureID}>
                                                                        <TableCell>{lecture.lectureID}</TableCell>
                                                                        <TableCell>{lecture.title}</TableCell>
                                                                        <TableCell>{lecture.videoURL}</TableCell>
                                                                        <TableCell>{lecture.resources}</TableCell>
                                                                        <TableCell>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                onClick={() => navigate(`/admin/users/${userID}/details/classID/${classItem.classID}/lectureID/${lecture.lectureID}`)}
                                                                            >
                                                                                성적 보기
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    ) : (
                                                        <Typography variant="body2">No lectures available.</Typography>
                                                    )}
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add Class</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new class.
                    </DialogContentText>
                    <Autocomplete
                        options={availableClasses}
                        getOptionLabel={(option) => `${option.className} (${option.classID})`}
                        value={selectedClass}
                        onChange={(event, newValue) => setSelectedClass(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Class" margin="dense" fullWidth />}
                    />
                    <TextField
                        margin="dense"
                        name="enrollmentDate"
                        label="Enrollment Date"
                        type="date"
                        value={newClass.enrollmentDate}
                        onChange={(e) => setNewClass({ ...newClass, enrollmentDate: e.target.value })}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddClass} color="primary" disabled={!selectedClass}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                <DialogTitle>Edit Enrollment Date</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit the enrollment date of the class.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        name="enrollmentDate"
                        label="Enrollment Date"
                        type="date"
                        value={editEnrollmentDate}
                        onChange={(e) => setEditEnrollmentDate(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default UserDetailPage;
