import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

export default function CourseDetailPanel({ open, onClose, course, handleNavigateToPayment }) {
    if (!open) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '25%', // 적절한 크기 설정
                height: '100vh', // 화면 전체 높이
                backgroundColor: '#fff',
                boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
                zIndex: 1300,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'row',
                minWidth: '400px',
                overflowX: 'hidden',
            }}
        >
            <Box sx={{ width: '91%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 2,
                        borderBottom: '1px solid #ddd'
                    }}
                >
                    <Typography variant="h6" fontWeight="bold">
                        수업 정보
                    </Typography>
                </Box>

                <Divider sx={{ borderWidth: '1.5px', borderColor: 'black' }} />

                {/* 수업 정보 */}
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        {course.className}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {course.description}
                    </Typography>
                </Box>

                <Divider sx={{ borderWidth: '1.5px', borderColor: 'black' }} />

                {/* 세부 정보 */}
                <Box sx={{ padding: 3, display: 'flex', flexDirection: 'column' }}>
                    <Divider sx={{ width: '100%', borderWidth: '1px', borderColor: 'black', my: '5px' }} />
                    <Typography variant="subtitle1" fontWeight="bold">수업 세부 정보:</Typography>
                    <Divider sx={{ width: '100%', borderWidth: '1px', borderColor: 'black', my: '5px' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ width: '20%' }}>
                            <Typography fontWeight="bold">수업 기간:</Typography>
                        </Box>
                        <Box sx={{ width: '80%' }}>
                            <Typography>{course.servicePeriod}일</Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: '100%', borderWidth: '1px', borderColor: 'black', my: '5px' }} />

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ width: '20%' }}>
                            <Typography fontWeight="bold">수업료:</Typography>
                        </Box>
                        <Box sx={{ width: '80%' }}>
                            <Typography>{Math.round(course.price).toLocaleString()} KRW</Typography>
                        </Box>
                    </Box>
                    <Divider sx={{ width: '100%', borderWidth: '1px', borderColor: 'black', my: '5px' }} />
                </Box>

                {/* 포함 사항 */}
                <Box sx={{ padding: 3 }}>
                    <Divider sx={{ width: '100%', borderWidth: '1px', borderColor: 'black', my: '5px' }} />
                    <Typography variant="subtitle1" fontWeight="bold">포함 사항:</Typography>
                    <Typography>- 실시간 수업 (LIVE)</Typography>
                    <Typography>- 동영상 해설강의 (VOD)</Typography>
                    <Typography>- 학습 성취도 리포트</Typography>
                    <Typography>- 1:1 질문 응답</Typography>
                    <Divider sx={{ width: '100%', borderWidth: '1px', borderColor: 'black', my: '5px' }} />
                </Box>



                {/* 다음 단계로 이동 */}
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '91%',
                        backgroundColor: '#fff',
                        padding: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        size='large'
                        color="primary"
                        fullWidth
                        onClick={() => handleNavigateToPayment(course)}
                    >
                        다음 단계로 이동하기
                    </Button>
                </Box>
            </Box>
            <Box sx={{ width: '8%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                    <Divider orientation='vertical' sx={{ height: '100%', borderLeft: '1px solid' }} />
                    <Button
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            display: 'flex',
                            flexDirection: 'column',

                        }}
                    >
                        <CloseIcon sx={{ fontSize: '28px', my: 1.2, color: 'black' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', transform: 'rotate(90deg)', color: 'black' }}>
                            닫기
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box >
    );
}