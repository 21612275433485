import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,      // 핸드폰
            sm: 600,    // 작은 화면 (핸드폰과 태블릿)
            md: 960,    // 태블릿
            lg: 1000,   // 1000px 기준
            xl: 1920,   // 큰 화면
        },
    },
    palette: {
        primary: {
            main: '#0f3e8e',
        },
        custom: {
            light: '#4f7aa2',
            dark: '#0a2b4d',
            default: '#0f3e8e',
            darker: '#0a1e3c',
            lightest: '#f0f0f0',
            bookmark: '#d32f2f',
            black: '#000000'
        }
    },
});

export default theme;