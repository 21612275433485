import React, { useState, useEffect } from "react";
import { Box, Button, Grid, CircularProgress, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import BoardItem from "./BoardItem";
import BoardForm from "./BoardForm";
import Pagination from "./Pagination";
import { useApi } from "../../../../contexts/Apicontext";
import { jwtDecode } from "jwt-decode";

const BoardList = () => {
  const [boardItems, setBoardItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [limit, setLimit] = useState(5); // 한 페이지에 표시할 콘텐츠 수
  const apiBaseUrl = useApi();
  const boardID = 2;

  useEffect(() => {
    fetchBoardItems();
    checkAdminStatus();
  }, [currentPage, limit]);

  const checkAdminStatus = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin === 1);
    }
  };

  const fetchBoardItems = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${apiBaseUrl}/boards/${boardID}/posts?page=${currentPage}&limit=${limit}`
      );
      const data = await response.json();

      setBoardItems(data.items);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Failed to fetch board items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setSelectedItem(item);
  };

  const handleDelete = async (id) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      try {
        const token = localStorage.getItem("token");
        await fetch(`${apiBaseUrl}/posts/${id}`, {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        });
        fetchBoardItems();
      } catch (error) {
        console.error("Failed to delete board item:", error);
      }
    }
  };

  const handleCreate = () => {
    setIsEditing(true);
    setSelectedItem(null);
  };

  const handleFormClose = () => {
    setIsEditing(false);
    fetchBoardItems();
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* 게시물 생성 버튼과 게시물 수 드롭다운 */}
      {isAdmin && (
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, width: "70%", mx: "auto" }}>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            게시물 생성
          </Button>
          <FormControl size="small" sx={{ minWidth: 150 }}>
            <InputLabel id="limit-label">게시물 수</InputLabel>
            <Select
              labelId="limit-label"
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setCurrentPage(1); // limit 변경 시 첫 페이지로 돌아감
              }}
              label="게시물 수"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      {/* 게시물 목록 */}
      <Grid container spacing={2} justifyContent="center">
        {isLoading ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          boardItems.map((item) => (
            <Grid item xs={12} key={item.id} sx={{ display: "flex", justifyContent: "center" }}>
              <BoardItem
                boardItems={boardItems}
                item={item}
                onEdit={handleEdit}
                onDelete={handleDelete}
                isAdmin={isAdmin}
                currentPage={currentPage}
                limit={limit}
                boardID={boardID}
              />
            </Grid>
          ))
        )}

        {/* 페이지네이션 */}
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        </Grid>
      </Grid>

      {/* 게시물 폼 */}
      {isEditing && (
        <BoardForm onClose={handleFormClose} item={selectedItem} isAdmin={isAdmin} />
      )}
    </Box>
  );
};

export default BoardList;