import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, MenuItem, Select, FormControl, InputLabel, Button, FormControlLabel, Checkbox, Box
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../../contexts/Apicontext';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../../Loading/Loading';

const TAGS = [
    "Information and Ideas",
    "Expression of Ideas",
    "Craft and Structure",
    "Standard English Conventions"
];

const DETAILED_TAGS = {
    "Information and Ideas": {
        "Command of Evidence": [],
        "Inference": [],
        "Central Ideas and Details": []
    },
    "Craft and Structure": {
        "Words in Context": [],
        "Text Structure and Purpose": [],
        "Cross-Text Connections": []
    },
    "Expression of Ideas": {
        "Rhetorical Synthesis": [],
        "Transitions": []
    },
    "Standard English Conventions": {
        "Boundaries": [],
        "Form, Structure, and Sense": ["SubjectVerbAgreement", "PronounCases"]
    }
};

const EnglishGeneratedQuestionsTable = ({ onPageChange }) => {
    const location = useLocation();
    const apiBaseUrl = useApi();
    const navigate = useNavigate();

    // State initialization
    const [questions, setQuestions] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [page, setPage] = useState(() => parseInt(new URLSearchParams(window.location.search).get('page'), 10) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(() => parseInt(new URLSearchParams(window.location.search).get('rowsPerPage'), 10) || 10);
    const [selectedTag, setSelectedTag] = useState(location.state?.selectedTag || '');
    const [selectedDetailedTag, setSelectedDetailedTag] = useState(location.state?.selectedDetailedTag || '');
    const [showImageOnly, setShowImageOnly] = useState(location.state?.showImageOnly || false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchQuestions();
    }, [page, rowsPerPage, selectedTag, selectedDetailedTag, showImageOnly]);

    const fetchQuestions = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const url = new URL(`${apiBaseUrl}/admin/generated-questions-english`);
        url.searchParams.append('page', page + 1);
        url.searchParams.append('limit', rowsPerPage);

        if (selectedTag) url.searchParams.append('tag', selectedTag);
        if (selectedDetailedTag) url.searchParams.append('detailedTag', selectedDetailedTag);
        if (showImageOnly) url.searchParams.append('showImageOnly', showImageOnly);
        console.log(showImageOnly);
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setQuestions(data.questions || []);
            setTotalQuestions(data.totalQuestions || 0);
        } catch (error) {
            console.error('Error fetching questions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMoreClick = (question) => {
        onPageChange(`ENG_AI_LIST/${question.GeneratedQuestionID}`, {
            state: {
                rawquestion: question,
                page,
                rowsPerPage,
                selectedTag,
                selectedDetailedTag,
                showImageOnly
            }
        });
    };

    const handleDeleteClick = async (questionID) => {
        if (window.confirm('Are you sure you want to delete this question?')) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiBaseUrl}/admin/generated-questions-english/${questionID}`, {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.ok) {
                    alert('Question deleted successfully.');
                    fetchQuestions();
                } else {
                    throw new Error('Failed to delete question.');
                }
            } catch (error) {
                console.error('Error deleting question:', error);
                alert('Error! Unable to delete question.');
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        updateUrlParams(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        updateUrlParams(0, newRowsPerPage);
    };

    const updateUrlParams = (newPage, newRowsPerPage) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', newPage);
        urlParams.set('rowsPerPage', newRowsPerPage);
        window.history.replaceState(null, '', `?${urlParams.toString()}`);
    };

    if (loading) return <Loading />;

    return (
        <div className='w-full'>
            {/* Filters */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel id="tag-select-label">Tag</InputLabel>
                    <Select
                        labelId="tag-select-label"
                        value={selectedTag}
                        onChange={(e) => {
                            setSelectedTag(e.target.value);
                            setSelectedDetailedTag('');
                        }}
                        label="Tag"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {TAGS.map(tag => (
                            <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="detailed-tag-select-label">Detailed Tag</InputLabel>
                    <Select
                        labelId="detailed-tag-select-label"
                        value={selectedDetailedTag}
                        onChange={(e) => setSelectedDetailedTag(e.target.value)}
                        label="Detailed Tag"
                        disabled={!selectedTag}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {(DETAILED_TAGS[selectedTag] || []).map(detailedTag => (
                            <MenuItem key={detailedTag} value={detailedTag}>{detailedTag}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showImageOnly}
                            onChange={(e) => setShowImageOnly(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={showImageOnly ? "Show All" : "Show Image Only"}
                />
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Difficulty</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Detailed Tags</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question) => (
                            <TableRow key={question.GeneratedQuestionID}>
                                <TableCell>{question.GeneratedQuestionID}</TableCell>
                                <TableCell>
                                    {question.content?.[0]?.value?.substring(0, 15) || 'No content'}
                                </TableCell>
                                <TableCell>{question.difficulty}</TableCell>
                                <TableCell>{question.tags}</TableCell>
                                <TableCell>{question.detailedTags}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleMoreClick(question)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(question.GeneratedQuestionID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalQuestions}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default EnglishGeneratedQuestionsTable;