import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from '@mui/material';
import { useApi } from '../../../contexts/Apicontext';
import { useParams } from 'react-router-dom';
import AttemptDetailsDialog from './AttemptDetailsDialog';

const QuizDetails = ({ quizID }) => {
    const apiBaseUrl = useApi();
    const { userID } = useParams();
    const [attemptsData, setAttemptsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedAttemptID, setSelectedAttemptID] = useState(null);

    useEffect(() => {
        fetchQuizAttempts();
    }, [quizID]);

    const fetchQuizAttempts = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${apiBaseUrl}/admin/users/${userID}/quizzes/${quizID}/attempts`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            setAttemptsData(data);
        } catch (error) {
            console.error('Failed to fetch quiz attempts:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDialogOpen = (attemptID) => {
        setSelectedAttemptID(attemptID);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedAttemptID(null);
    };

    return (
        <Box sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
            <Typography variant="subtitle1" gutterBottom>
                Quiz Attempts for Quiz ID: {quizID}
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : attemptsData ? (
                <>
                    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Non-Timed Tests
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 3 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Attempt ID</TableCell>
                                    <TableCell>Quiz ID</TableCell>
                                    <TableCell>Start Time</TableCell>
                                    <TableCell>End Time</TableCell>
                                    <TableCell>Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attemptsData.isTimedTest_0.map((attempt) => (
                                    <TableRow key={attempt.attemptID}>
                                        <TableCell>{attempt.attemptID}</TableCell>
                                        <TableCell>{attempt.quizID}</TableCell>
                                        <TableCell>
                                            {new Date(attempt.startTime).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {attempt.endTime
                                                ? new Date(attempt.endTime).toLocaleString()
                                                : 'In Progress'}
                                        </TableCell>
                                        <TableCell>{attempt.score || 'N/A'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Timed Tests
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Attempt ID</TableCell>
                                    <TableCell>Quiz ID</TableCell>
                                    <TableCell>Start Time</TableCell>
                                    <TableCell>End Time</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attemptsData.isTimedTest_1.map((attempt) => (
                                    <TableRow key={attempt.attemptID}>
                                        <TableCell>{attempt.attemptID}</TableCell>
                                        <TableCell>{attempt.quizID}</TableCell>
                                        <TableCell>
                                            {new Date(attempt.startTime).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(attempt.endTime).toLocaleString()}
                                        </TableCell>
                                        <TableCell>{attempt.score}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDialogOpen(attempt.attemptID)}
                                            >
                                                시험 결과보기
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <AttemptDetailsDialog
                        attemptID={selectedAttemptID}
                        open={dialogOpen}
                        onClose={handleDialogClose}
                    />
                </>
            ) : (
                <Typography>No Attempts Data Available</Typography>
            )}
        </Box>
    );
};

export default QuizDetails;