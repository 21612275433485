import React from "react";
import { Box, Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useSelector } from "react-redux";

const StorageUpperButtons = ({
  onClickUpFolder,
  onClickCreateFolder,
  onFileUpload,
  isRootFolder, // Receive the root folder status
}) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
      {/* 상위 폴더로 이동 버튼 */}
      <Button
        variant="outlined"
        onClick={onClickUpFolder}
        startIcon={<ArrowUpwardIcon />}
        disabled={isRootFolder} // Disable button if in the root folder
        sx={{
          borderRadius: "8px",
          padding: "8px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          color: isRootFolder ? "gray" : "black",
          border: `2px solid ${isRootFolder ? "gray" : "#0f3e8e"}`,
          "&:hover": {
            backgroundColor: isRootFolder ? "inherit" : "#f0f0f0",
            borderColor: isRootFolder ? "gray" : "#0f3e8e",
            borderWidth: "2px",
          },
        }}
      >
        상위 폴더로 이동
      </Button>

      {/* 새 폴더 추가 */}
      {user?.isAdmin === 1 && (
        <Button
          variant="contained"
          color="primary"
          onClick={onClickCreateFolder}
          startIcon={<FolderIcon />}
          sx={{
            borderRadius: "8px",
            padding: "8px 16px",
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: "#4caf50",
            color: "white",
            "&:hover": {
              backgroundColor: "#45a045",
            },
          }}
        >
          폴더 추가
        </Button>
      )}

      {/* 파일 업로드 */}
      {user?.isAdmin === 1 && (
        <Button
          variant="contained"
          component="label"
          startIcon={<UploadFileIcon />}
          sx={{
            borderRadius: "8px",
            padding: "8px 16px",
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": {
              backgroundColor: "#1976d2",
            },
          }}
        >
          파일 추가
          <input type="file" hidden onChange={onFileUpload} />
        </Button>
      )}
    </Box>
  );
};

export default StorageUpperButtons;