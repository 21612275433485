// src/components/MainPage_components/MainPageHeader.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    Menu,
    MenuItem,
    Drawer,
    ThemeProvider
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import { jwtDecode } from 'jwt-decode';
import LoginPage from './LoginPage';
import theme from '../../theme/theme';


// 스타일 정리
const buttonStyle = {
    bgcolor: 'inherit',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    borderRadius: '8px',
    border: '2px solid #0f3e8e',
    mx: 0.5,
    py: 0.5,
    px: 1,
    height: '100%',
    '&:hover': {
        bgcolor: 'grey.900',
        border: '2px solid white',
    },
};

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.auth.user);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    

    // 로그인된 사용자의 관리자 여부 확인
    const token = localStorage.getItem('token');
    const isAdmin = token ? jwtDecode(token)?.isAdmin === 1 : false;

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleLogout = () => {
        if (window.confirm('Are you sure you want to logout?')) {
            dispatch(logoutUser());
            localStorage.removeItem('token');
            navigate('/');
        }
    };

    const closeDrawer = () => setIsDrawerOpen(false);

    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="fixed"
                color="default"
                elevation={0}
                sx={{ borderBottom: '1px solid #0f3e8e', zIndex: 1201 }}
            >
                <Toolbar sx={{ flexWrap: 'wrap', bgcolor: '#0f3e8e' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {/* 로고와 제목 */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                            <AccountBalanceIcon
                                sx={{ mr: 1, color: 'white', cursor: 'pointer' }}
                                onClick={() => navigate('/')}
                            />
                            <Typography
                                variant="h5"
                                sx={{ color: 'white', fontWeight: 'bold', cursor: 'pointer', mr: 5 }}
                                onClick={() => navigate('/')}
                            >
                                EDU AGORA
                            </Typography>
                            {/* Navigation Buttons */}
                            <Button component={Link} to="/courses" sx={buttonStyle}>
                                Courses
                            </Button>
                            <Button
                                sx={buttonStyle}
                                aria-controls="board-menu"
                                aria-haspopup="true"
                                onMouseEnter={handleMenuOpen}
                            >
                                Board
                            </Button>
                            <Menu
                                id="board-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                    onMouseLeave: handleMenuClose,
                                }}
                                sx={{ mt: 1.2 }}
                            >
                                <MenuItem
                                    component={Link}
                                    to="/board"
                                    onClick={handleMenuClose}
                                    sx={{ fontSize: '16px', py: 1, px: 2, '&:hover': { bgcolor: '#e0e0e0' } }}
                                >
                                    자료실
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="/board/announcement"
                                    onClick={handleMenuClose}
                                    sx={{ fontSize: '16px', py: 1, px: 2, '&:hover': { bgcolor: '#e0e0e0' } }}
                                >
                                    공지사항
                                </MenuItem>
                            </Menu>
                            {isAuthenticated && (
                                <>
                                    {user?.role === 'student' && (
                                        <>
                                            <Button onClick={() => navigate('/student')} sx={buttonStyle}>
                                                My Lectures
                                            </Button>
                                            <Button onClick={() => navigate('/myprogress')} sx={buttonStyle}>
                                                My Progress
                                            </Button>
                                        </>
                                    )}
                                    {user?.role === 'teacher' && (
                                        <Button onClick={() => navigate('/teacher')} sx={buttonStyle}>
                                            My Courses
                                        </Button>
                                    )}
                                    {isAdmin && (
                                        <Button onClick={() => navigate('/admin')} sx={buttonStyle}>
                                            Admin Page
                                        </Button>
                                    )}
                                </>
                            )}
                        </Box>
                        {/* 로그인/로그아웃 및 프로필 */}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '30%' }}>
                            {isAuthenticated ? (
                                <>
                                    <Button onClick={handleLogout} sx={buttonStyle}>
                                        Logout
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/myprofile"
                                        sx={{
                                            ...buttonStyle,
                                            fontSize: 14,
                                            borderColor: '#0f3e8e',
                                        }}
                                    >
                                        <PersonIcon sx={{ mr: 1 }} />
                                        My Profile
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => setIsDrawerOpen(true)} sx={buttonStyle}>
                                        Login
                                    </Button>
                                    <Button component={Link} to="/signup" sx={buttonStyle}>
                                        Sign Up
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* Drawer for Login */}
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={closeDrawer}
                sx={{
                    '& .MuiDrawer-paper': { bgcolor: 'transparent', boxShadow: 'none' },
                }}
                BackdropProps={{ invisible: true }}
            >
                <LoginPage closeDrawer={closeDrawer} />
            </Drawer>
            {/* Spacer for Fixed Header */}
            <Toolbar />
        </ThemeProvider>
    );
};

export default Header;