// src/components/MainPage_components/PaymentPage/PaymentPage.js

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, TextField, Button, Card, CardContent, Grid, Stack, Divider } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import { useApi } from '../../../contexts/Apicontext';


const PaymentPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { course } = location.state || {};
    const token = localStorage.getItem('token');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null); // 상태 추가
    const [address, setAddress] = useState({
        country: '한국',
        Name: jwtDecode(token).name,
        email: '',
        phoneNumber: '',
    });
    const apiBaseUrl = useApi();

    const handleLogout = () => {
        if (window.confirm('로그아웃 하시겠습니까?')) {
            dispatch(logoutUser());
            localStorage.removeItem('token');
            navigate('/');
        }
    };

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Decoded JWT:', decoded);
            setAddress((prevState) => ({
                ...prevState,
                Name: decoded.name || '', // JWT에서 이름 가져오기
                email: decoded.email || '', // JWT에서 이메일 가져오기 (필요한 경우)
                phoneNumber: decoded.phoneNumber || '', // JWT에서 전화번호 가져오기 (필요한 경우)
            }));
        }
    }, [token]);

    if (!course) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="h6">결제할 수업 정보가 없습니다.</Typography>
            </Box>
        );
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddress((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const saveAddress = () => {
        console.log('Saved Address:', address);
        alert('정보가 저장되었습니다.');
    };

    const handlePayment = async (paymentMethod) => {
        try {
            // 결제 요청 데이터 생성
            const token = localStorage.getItem('token');

            const decoded = jwtDecode(token);

            const paymentData = {
                productname: course.className,
                productprice: course.price,
                buyerName: address.Name,
                userID: decoded.userID,
                buyerEmail: address.email,
                payStatus: paymentMethod,
                retuenUrl: 'http://localhost:3000/payment/success',
                closeUrl: 'http://localhost:3000/payment/close',
                courseID: course.courseID,

            };

            // 서버로 결제 요청 전송
            const response = await fetch(`${apiBaseUrl}/payment/initiate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            });

            const responseData = await response.json();

            if (responseData.success) {
                // 결제 창 띄우기
                const { paymentUrl, paymentData } = responseData;

                // 실제로 결제 요청 창을 띄우는 코드
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = paymentUrl;

                Object.entries(paymentData).forEach(([key, value]) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                });

                document.body.appendChild(form);
                form.submit();
            } else {
                alert('결제 요청에 실패했습니다. 다시 시도해주세요.');
            }
        } catch (error) {
            console.error('Error during payment:', error);
            alert('결제 요청 중 오류가 발생했습니다.');
        }
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            {/* Main Content Box - 5/6 of the screen */}
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {/* All the current content of PaymentPage */}
                <Box sx={{ p: 3, width: '80%', minWidth: '600px' }}>
                    {/* Step 1: Login Section */}
                    <Box
                        sx={{
                            border: '1px solid #d9e1e7',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#d9e1e7',
                            marginBottom: '24px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '16px' }}>
                                1
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                로그인 아이디: {address.Name}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#3d3d3d',
                                color: '#fff',
                                fontSize: '0.875rem',
                                '&:hover': {
                                    backgroundColor: '#2d2d2d',
                                },
                            }}
                            onClick={handleLogout}
                        >
                            다른 아이디로 로그인
                        </Button>
                    </Box>

                    {/* Step 2: Payment Information Section */}
                    <Box
                        sx={{
                            border: '1px solid #d9e1e7',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#d9e1e7',
                            marginBottom: '24px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '16px' }}>
                                2
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                결제 정보 입력
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="이름"
                                    name="name"
                                    value={address.Name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="이메일"
                                    name="email"
                                    value={address.email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="전화번호"
                                    name="phone"
                                    value={address.phoneNumber || ''}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ textAlign: 'right', mt: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: '#3d3d3d',
                                    color: '#fff',
                                    fontSize: '0.875rem',
                                    '&:hover': {
                                        backgroundColor: '#2d2d2d',
                                    },
                                }}
                                onClick={saveAddress}
                            >
                                정보 저장
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            border: '1px solid #d9e1e7',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#d9e1e7',
                            marginBottom: '24px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '16px' }}>
                                3
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                결제 정보 확인
                            </Typography>
                        </Box>

                        <Card variant="outlined" sx={{ mb: 3 }}>
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <strong>수업명:</strong> {course.className}
                                </Typography>
                                <Typography variant="subtitle1">
                                    <strong>수업료:</strong>{' '}
                                    {Math.round(course.price).toLocaleString()} KRW
                                </Typography>
                            </CardContent>
                        </Card>

                        <Stack spacing={2} direction="row" justifyContent="center" sx={{ mb: 3 }}>
                            <Button
                                variant={selectedPaymentMethod === 0 ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => setSelectedPaymentMethod(0)} // 상태 변경
                            >
                                카드 결제
                            </Button>
                            <Button
                                variant={selectedPaymentMethod === 1 ? 'contained' : 'outlined'}
                                color="secondary"
                                onClick={() => setSelectedPaymentMethod(1)} // 상태 변경
                            >
                                무통장 입금
                            </Button>
                        </Stack>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                            * "주문완료" 버튼을 누르면 결제 페이지로 이동합니다.
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={() => handlePayment('completeOrder')}
                        >
                            주문완료
                        </Button>
                    </Box>
                </Box>
                {/* Empty Space - 1/6 of the screen */}
                <Box
                    sx={{
                        width: '20%',
                        borderLeft: '1px solid #d9e1e7',
                        paddingLeft: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '200px'
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                        주문 요약
                    </Typography>
                    <Divider sx={{ mb: 2, borderWidth: '1px' }} />
                    <Box sx={{ display: 'flex', mb: 2, flexDirection: 'column' }}>
                        <Box>
                            <Typography variant="h6" fontWeight='bold'>
                                수업
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">
                                {course.className}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6">
                            <strong>수업료:</strong>
                        </Typography>
                        <Typography variant="h6">
                            {Math.round(course.price).toLocaleString()} 원
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentPage;