import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Paper, Divider } from "@mui/material";
import "./quillEditorStyles.css";

const MAX_CONTENT_LENGTH = 100; // 표시할 최대 content 길이

const BoardItem = ({ boardItems, item, onEdit, onDelete, isAdmin, currentPage, limit }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const navigate = useNavigate();

  // Content를 잘라서 표시
  const truncatedContent =
    item.content.length > MAX_CONTENT_LENGTH
      ? `${item.content.substring(0, MAX_CONTENT_LENGTH)}...`
      : item.content;

  const handleItemClick = () => {
    // navigate에 state를 이용해 boardItems를 함께 전달
    navigate(`/board/${item.boardID}/post/${item.postID}?currentPage=${currentPage}`, {
      state: { boardItems, limit },
    });
  };

  return (
    <Paper
      elevation={0}
      className="prose"
      sx={{
        px: 2,
        mb: 2,
        width: "70%",
        minHeight: "200px", // 최소 높이 설정
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&:hover": { backgroundColor: "#f5f5f5", cursor: "pointer" },
        transition: "background-color 0.3s",
      }}
      onClick={handleItemClick} // 클릭 시 상세 페이지로 이동
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          {item.title}
        </Typography>
        <div
          className="prose"
          style={{ marginBottom: "8px" }}
          dangerouslySetInnerHTML={{
            __html: showFullContent ? item.content : truncatedContent,
          }}
        />
        {/* "더보기" 버튼 */}
        {item.content.length > MAX_CONTENT_LENGTH && (
          <Button
            onClick={(e) => {
              e.stopPropagation(); // 부모 클릭 이벤트 방지
              setShowFullContent(!showFullContent);
            }}
            sx={{ textTransform: "none", mb: 2 }}
          >
            {showFullContent ? "간략히 보기" : "더보기"}
          </Button>
        )}
      </Box>
      {isAdmin && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1, borderRadius: "8px" }}
            onClick={(e) => {
              e.stopPropagation(); // 부모 클릭 이벤트 방지
              onEdit(item);
            }}
          >
            수정
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: "8px" }}
            onClick={(e) => {
              e.stopPropagation(); // 부모 클릭 이벤트 방지
              onDelete(item.postID);
            }}
          >
            삭제
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 0 }} />
    </Paper>
  );
};

export default BoardItem;